import CreateComment from "../components/CommentForm";
import React, { Component,useEffect, useState } from "react";

import axios, { AxiosResponse } from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';
import {order} from './OC';

import { FaThumbsUp } from "react-icons/fa";
import { FaThumbsDown } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";

import CreateReply from "./CommentFormv2";
import Anchor from "./Anchor";
import "./threads.css";

import InfiniteScroll from "react-infinite-scroll-component";

import AxiosInstance from "../utils/axiosBase";
import CreateAccount from "./CreateAccount";
import { UserContext } from "../pages/inputs/tinyMce";
import sanitizeHtml from 'sanitize-html';


const Rate = (value, comment) => {
    const access = localStorage.getItem("access");
    const config = {
        headers: {
            "content-type": 'application/json',
            AUTHORIZATION: "JWT " + access
        }
    };
    const data = {
        comment:comment,
        rating:value
    }
    
    AxiosInstance.post("comments/rate/", data, config)
        .then(response => {
            //alert("Post Vote Successfully");
            window.location.reload();
        })
        .catch(error => {
            //alert("Rating failed - try again later!");
        });

};

const Flag = (comment_) => {
    const access = localStorage.getItem("access");
    const config = {
        headers: {
            "content-type": 'application/json',
            AUTHORIZATION: "JWT " + access
        }
    };
    const data = {
        comment:comment_
    }    
    AxiosInstance.post("comments/flag/", data, config)
        .then(response => {
            alert("Post Flag Submitted.");
            
        })
        .catch(error => {
            //alert("Rating failed - try again later!");
        });

};


function Reply({item, slug, authed}){
        

        const [show, setShow] = useState(false);
        const [isVisible, setIsVisible] = useState(false);
        const expand = () => {
            setIsVisible(!isVisible);
        };

        
        var OneDay = new Date().getTime() /(60 * 60 * 1000)

        var yourDate = new Date(item.published_on).getTime() /(60 * 60 * 1000)       

        var published_on = ''
        if (OneDay - yourDate < 24) {
            // The yourDate time is less than 24 hrs old
            var hours_old = OneDay - yourDate;
            if(OneDay - yourDate < 1){ // if less than one hour, how many minutes ago? 
                hours_old = hours_old * 60; //minutes
                published_on = <p>{Math.ceil(hours_old)} minutes ago</p>;
            }
            else{
                published_on = <p>{Math.ceil(hours_old)} hours ago</p>;
            }
        }
        else {
            published_on = <p>{new Date(item.published_on).toLocaleString('default', { month: 'short' })} {new Date(item.published_on).getDate()} at {new Date(item.published_on).toLocaleTimeString()}</p>;
            // The yourDate time is exactly/more than 1 days from now
        }
        const org_ = item.original;
        var from_other_page = false;
        var home = false;
        var from_at = false;
        var from_co = false;
        var from_ = null;
        
        if(org_.match('(?<=[#$@])[a-zA-Z_]+')[0] != slug.toLowerCase() & 
        org_.match('(?<=[#$@])[a-zA-Z_]+')[0] != slug.toUpperCase()
        ){
            
            var from_ = org_.match('(?<=[#$@])[a-zA-Z_]+')[0];
            if(from_ == 'home'){
                var home = true;
                from_other_page = true;
            }
            try{
                var from_at = org_.match('(?<=[#@])[a-zA-Z_]+')[0];
                if(from_at != ''){
                    from_at = true
                    from_other_page = true;
                }
                
            }catch{}

            try{
                var from_co = org_.match('(?<=[#$])[a-zA-Z_]+')[0];
                if(from_co != ''){
                    from_co = true
                    from_other_page = true;
                }
                
                
            }catch{}           
        }

        // replace $home before 
        var outbounds_ = [...item.body.matchAll('(?<=[@])[a-zA-Z_]+')];
        outbounds_.forEach((outbound_) => {
            var target_length = outbound_[0].length;
            var outbound_index = item.body.indexOf(outbound_);
                if(outbound_ == 'home'){
                    item.body = item.body.slice(0,outbound_index) + "<a href='/'><b>"+outbound_+"</b></a>" + item.body.slice(outbound_index+target_length)
                    //item.body = item.body.replace(outbound_[i], "<a href='/'><b>"+outbound_[i]+"</b></a>"); 

                }else{
                    item.body = item.body.slice(0,outbound_index) + "<a href='/profile/"+outbound_+"'><b>"+outbound_+"</b></a>" + item.body.slice(outbound_index+target_length)
                }
        });

        var outbounds_ = [...item.body.matchAll('(?<=[#])[a-zA-Z_]+')];
        outbounds_.forEach((outbound_) => {
            var target_length = outbound_[0].length;
            var outbound_index = item.body.indexOf(outbound_);
            if(outbound_ == 'home'){
                item.body = item.body.slice(0,outbound_index) + "<a href='/'><b>"+outbound_+"</b></a>" + item.body.slice(outbound_index+target_length)
                //item.body = item.body.replace(outbound_[i], "<a href='/'><b>"+outbound_[i]+"</b></a>"); 

            }else{
                item.body = item.body.slice(0,outbound_index) + "<a href='/page/"+outbound_+"'><b>"+outbound_+"</b></a>" + item.body.slice(outbound_index+target_length)
            }
        });
        var outbounds_ = [...item.body.matchAll('(?<=[$])[a-zA-Z_]+')];
        outbounds_.forEach((outbound_) => {
            var target_length = outbound_[0].length;
            var outbound_index = item.body.indexOf(outbound_);

            if(outbound_ == 'home'){
                item.body = item.body.slice(0,outbound_index) + "<a href='/'><b>"+outbound_+"</b></a>" + item.body.slice(outbound_index+target_length)

            }else{
                item.body = item.body.slice(0,outbound_index) + "<a href='/company/"+outbound_+"'><b>"+outbound_+"</b></a>" + item.body.slice(outbound_index+target_length)
            }
        });

        // toggle extension of message
        const [isOpen, setIsOpen] = useState(false);

        const toggle = () => {
            setIsOpen(!isOpen);
        };

        const [showMore, setShowMore] = useState(true);


        const [buttonLikes, setButtonLikes] = useState(item.rating);
        const handleClickLikeButton = (value, comment_id) => {
            setButtonLikes((prevValue) => prevValue + value)
            Rate(value, comment_id)
        }

        const handleClickFlagButton = (value, comment_id) => {
            //setFlag((prevValue) => True)
            Flag(value)
        }

        function sanitizeHtmlfnc(dirty){
            return(sanitizeHtml(dirty, {
                allowedTags: ['b', 'i', 'p', 'strong', 'a' , 'table', 'thead', 'tr', 'th', 'td', 'img', 'src'],
                allowedAttributes: {
                  a: ['href'],
                }
              }));
          };
        
        
        return (
            <>
                {item.replies ? (
                    
                <div className="comment-thread">
                        <div className="comment" id="comment-1">
                            
                            <div className="comment-heading">
                                <div className="comment-voting">
                                    <button type="button" onClick={() => handleClickLikeButton(1, item.id)}>
                                        <span aria-hidden="true">&#9650;</span>
                                        <span className="sr-only">Vote up</span>
                                    </button>
                                    <button type="button"><span align="center">{buttonLikes}</span></button>
                                    <button type="button" onClick={() => handleClickLikeButton(-1, item.id)}>
                                        <span aria-hidden="true">&#9660;</span>
                                        <span className="sr-only">Vote down</span>
                                    </button>
                                </div>
                                <div className="comment-info">
                                     {item.handle? 
                                        <a href={'profile/'+item.handle} className="comment-author"> 
                                            <p>{item.handle}</p> 
                                        </a>
                                        : 
                                        <a href={'profile/'+item.author_full_name} className="comment-author">
                                            <p>{item.author_full_name} </p>
                                        </a>
                                        }
                                    
                                    <p className="m-0">
                                        &bull; {published_on}
                                    </p>
                                </div>
                            </div>
                            

                            <div className="comment-body">
                            {item.body.length > 50 ?
                                (
                                <div>
                                    {showMore ? (<>
                                    <p> <div style={{"white-space": "pre-wrap"}} dangerouslySetInnerHTML={{__html: sanitizeHtmlfnc(item.body.substring(0,150))}} />... 
                                    <button onClick={() => setShowMore(!showMore)}>More...</button> </p></>)
                                    :(
                                        <>
                                        <p> <div style={{"white-space": "pre-wrap"}} dangerouslySetInnerHTML={{__html: sanitizeHtmlfnc(item.body)}} />
                                        <button onClick={() => setShowMore(!showMore)}>Less... </button></p>
                                        </>
                                    )}

                                </div>
                                ) :
                                (< p style={{"white-space": "pre-wrap"}} dangerouslySetInnerHTML={{__html: sanitizeHtmlfnc(item.body)}} />)
                                    }
                                {
                                home? 
                                    (<a href={'/'}><i>- from {from_} </i></a>):
                                    (from_co ? <a href={'/company/'+from_}><i>- from {org_} </i></a>:(
                                        from_at ? <a href={'/profile/'+from_}><i>- from {org_} </i></a> : <></>
                                    ))                           
                                    }
                            
                            {item.replies.length > 0? <button type="button" onClick={expand}>View Thread ({item.replies.length})</button>:<></>}
                                
                                {authed? 
                                
                                (
                                <>
                                <button type="button" onClick={() => setShow(!show)}>Reply</button>
                                <button type="button" onClick={() => handleClickFlagButton(item.id)}>Flag</button>
                                <div hidden={ !show ?  true : false }>
                                    <CreateReply master={item} slug={slug}/>
                                </div></>
                                ):(<></>)
                                }
                                
                                
                            </div>

         {isVisible ? (
                item?.replies?.map((child, i) => {
                return (
                    <div style={{ paddingLeft: 10 }}>
                    <Reply key={i} item={child} slug={slug}/>
                    </div>
                );
                })
            ) : (<></>)}
              
                        </div>
                    </div>

                )
            :
            (<></>)
            
        }
        </>
);

};


class CommentCreateManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            comments: [],
            slug: this.props.slug,
            nextPage: null, 
            moreContent: true,
            endMessage:null,
            show_comment_create:false,
        };
    };

    async componentDidMount() {
        
        const access = localStorage.getItem("access");
        if(access===null){
            this.state.show_comment_create=false;
        }
        
        AxiosInstance.get("/comments/management/" + this.state.slug + "/")
            .then(response => {
                
                this.setState({data: response});
                this.setState({ comments: order(response.data) });
                this.setState({nextPage: response.data.next})

                this.state.nextPage != null ? this.setState({moreContent:true}) : this.setState({moreContent:false});

                this.setState({endMessage:"That's all!"});
            })
            .catch(error => {
                this.setState({moreContent:false});
                
            });
            
      };

    

      fetchMoreData= () => {
        if(this.state.moreContent){
            if(this.state.nextPage != null){
                try{
                    fetch(this.state.nextPage).then(response => {response.json().then(res =>{
                        this.setState({nextPage: res.next});
                        this.setState({ comments: [...this.state.comments, ...order(res)] });
                })})
                    .catch((err) => {
                        this.setState({moreContent: false})
                    })
                }catch (err){
                    this.setState({moreContent: false})
                }
            }
            else{
                this.setState({moreContent: false})
            }
        }
      };
     
      

      render(){
        
        return (
            <>
            <Container>  
                    
                {this.state.comments.length>0 ? (
                    <div id="macommdiv" style={{height:"500px", overflow:'auto'}} ref={(ref) => this.scrollParentRef = ref}>
                    
                    <InfiniteScroll
                        dataLength={this.state.comments.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.moreContent}
                        loader={this.state.moreContent ? (<h4>Loading...</h4>):(<></>)}
                        scrollableTarget="macommdiv"
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                              <b>You have seen it all ~</b>
                            </p>
                          }
                    >

                    {this.state.comments && this.state.comments.map((item, i) => {
                        return(
                            <Row key={i}>
                                <div id={'#'+item.id}>
                                    <Row>
                                        <Reply item={item} slug={this.state.slug} authed={this.state.show_comment_create}/>
                                    </Row>
                                </div>
                            </Row>
                        );
                    })
                    }
                    </InfiniteScroll></div>
                ):(<><h3>No management posts ~ Share the page with them!</h3></>)}
      
            </Container>
            
            </>
        );
    }
}

export default (props) => (
    <CommentCreateManagement
        {...props}
        params={useParams()}
    />
);