import React from "react";

import Table from './helper/table'

import "./helper/tableTheme.css";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import NewsContainer from "./helper/newsContainer";

import CommentCreate from "../components/CommentCreate";
import ComNav from "../components/CommodityBar";

import Portfolio from './helper/portfolioData';

import Trending from "./helper/trending";


import Navbar from 'react-bootstrap/Navbar';

import Tabs from "react-bootstrap/Tabs";
import Tab from 'react-bootstrap/Tab';
import PortfolioData from "./helper/portfolioData";

const HomePage = () => {


  return (
    <>


    <Container >
      <Row><ComNav/></Row>
      <Row><PortfolioData/></Row>
      <Tabs fill defaultActiveKey='news'>
          <Tab eventKey="news" title="News">
            < NewsContainer/>
          </Tab>

          <Tab eventKey='chat' title="Chat">
            <CommentCreate slug='home'/>
          </Tab>
      </Tabs>
    </Container>
    </>
  );

};

export default HomePage;