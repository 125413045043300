import Trending from "./helper/trending";
import Col from 'react-bootstrap/Col';
import { Container } from "react-bootstrap";

const TrendPage = () => {


    return (
        <>

    
    <Container >
        <Col>
          <h3>Trending</h3>
          <Trending/>
        </Col>
        </Container></>
    )
}

export default TrendPage;