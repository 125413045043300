import React, { Component } from "react";
import Navbar from 'react-bootstrap/Navbar';
import AxiosInstance from "../utils/axiosBase";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import "./CommodityBar.css";


class ComNav extends Component{
    constructor(props) {
        super(props);
        this.state = {
            comms: [],
            hasData:false,
        };
    }
    async componentDidMount() {
        AxiosInstance.get("/app/homepage-commodity-price/home")
            .then(response => {
                this.setState({comms:response.data, hasData:true});
                
            })
            .catch(error => {
                this.setState({hasData:false});
            });
      };

  render() {
    var comms = null;
    if (this.state.hasData){
            comms = this.state.comms.map((item,i) => 
            <a href={"page/"+item.pagename}>
                <Button size="sm" variant={item.change > 0 ? 'outline-success' : 'outline-danger'} key={i}><p>#{item.pagename}</p> <p>{parseFloat(item.last).toFixed(4)}</p>
                <span className="d-none d-md-block"><p>{parseFloat(item.changeprecent).toFixed(2)}%</p></span></Button></a>); 
            return(
                <Col xs={12} md={12} lg={16}>
                <Row>
                    <div>{comms}
                        <a href={"/commodity-table"}>
                            <Button size="sm" variant={'outline-warning'}>
                                <p>Full</p>
                                <p>List</p>
                            </Button>
                        </a>
                        
                    </div>
                </Row>
                </Col>)
        }
    else{
        return(<></>)
    }
    
  };
  
};

export default ComNav;