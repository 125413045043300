// Post List For Dashboard

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Input from "../inputs/inputs";

//import cssClass from "./PostList.css";
import cssClass from "./CreatePost.css";
import Spinner from "../../components/UI/spinner";
import { connect } from "react-redux";
import { checkValidity } from "../formCheck/checkValidity";
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useEditor, EditorContent, FloatingMenu, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import axios from "axios";
import AxiosInstance from "../../utils/axiosBase";


var UserContext = React.createContext(null);




function Editor({props, called}) {

    const editor = useEditor({
        extensions: [StarterKit],
        onUpdate({ editor }) {
          UserContext = String(editor.getHTML());        
        },
        content: props.config.value
      });
        
    
 
    return <EditorContent key={props.id} 
    editor={editor} {...props.elementConfig}
    onChange={props.changed}/>;
  }


function userPostEdit(updatedPost){
    const access = localStorage.getItem("access");
    const config = {
        headers: {
            "content-type": 'application/json',
            AUTHORIZATION: "JWT " + access
        }
    };
    AxiosInstance.post("dashboard/update-post/", updatedPost, config)
        .then(response => {
            alert("Post Updated Successfully");
            
        })
        .catch(error => {
            //alert("Something Went Wrong... Try Again");
            
        });
    
};

class PostEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postEditForm: null,
            isPostEditFormValid: false,
            userPost: null,
            slug: this.props.params.slug,
            access : localStorage.getItem("access"),
        };
    }

    

    componentWillMount() {
        const config = {
            headers: {
                "content-type": 'application/json',
                AUTHORIZATION: "JWT " + this.state.access
            }
        };
        AxiosInstance.get(
            `dashboard/view/${this.state.slug}/`, config
        ).then(response => {
            this.setState({
                postEditForm: {
                    title: {
                        elementType: "input",
                        elementConfig: {
                            type: "input",
                            placeholder: "Title Of The Post"
                        },
                        value: response.data.title,
                        validation: {
                            required: true,
                            minLength: 0
                        },
                        valid: true,
                        touched: true
                    },
                    body: {
                        elementType: "TextField",
                        elementConfig: {
                            type: "TextField",
                            placeholder: "Body Of The Post"
                        },
                        value: response.data.body,
                        validation: {
                            required: true,
                            minLength: 0
                        },
                        valid: false,
                        touched: true
                    },
                    short_description: {
                        elementType: "input",
                        elementConfig: {
                            type: "input",
                            placeholder: "Short Description Of The Post"
                        },
                        value: response.data.short_description,
                        validation: {
                            required: true,
                            minLength: 0
                        },
                        valid: true,
                        touched: true
                    }
                }
            });
        });
    }

    inputChangedHandler = (event, inputIndentifier) => {

        const updatedPostEditForm = {
            ...this.state.postEditForm
        };
        const updatedFormElement = {
            ...updatedPostEditForm[inputIndentifier]
        };
        if(true){
            // body check
            const updatedPostEditFormBody = {
                ...this.state.postEditForm
            };
            const updatedFormElementBody = {
                ...updatedPostEditFormBody.body
            };
            updatedFormElementBody.value = UserContext

            updatedFormElementBody.valid = true;
            updatedFormElementBody.touched = true;
            updatedPostEditForm["body"] = updatedFormElementBody;
            
            console.log(this.state.postEditForm);
        }
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValidity(
            updatedFormElement.value,
            updatedFormElement.validation
        );
        updatedFormElement.touched = true;
        updatedPostEditForm[inputIndentifier] = updatedFormElement;
        let isFormValid = true;
        for (let inputIndentifier in updatedPostEditForm) {
            isFormValid =
                updatedPostEditForm[inputIndentifier].valid && isFormValid;
        }
        this.setState({
            postEditForm: updatedPostEditForm,
            isPostEditFormValid: isFormValid
        });
    };

    onFormSubmitEventHandler = event => {
        event.preventDefault();
        let updatedForm = {};
        for (let key in this.state.postEditForm) {
            updatedForm[key] = this.state.postEditForm[key].value;
        }
        updatedForm["slug"] = this.props.params.slug;
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
        };
        AxiosInstance.post("/dashboard/update-post/", updatedForm, config)
            .then(function(response){
                    alert("Post Updated Successfully!");
                    window.location = '/list-post/'
                }
            )
            .catch(error => {
                    alert(error);
                }
        );
    };

    render() {
        let formElements = [];
        for (let key in this.state.postEditForm) {
            formElements.push({
                id: key,
                config: this.state.postEditForm[key]
            });
        }

        let form = <Spinner />;
        if (this.state.postEditForm) {
            form = (
                <>
                <Container>
                    <Row>
                        <Col>
                    <h1
                        style={{
                            fontFamily: "Roboto, sans-serif",
                            fontWeight: "200"
                        }}
                    >
                        Edit Post
                    </h1>
                    <form onSubmit={this.onFormSubmitEventHandler}>
                        {formElements.map(formElement => {
                            if(formElement.config.elementType=='TextField')
                                
                                return <Editor props={formElement} onUpdate={event => this.inputChangedHandler("Body", "TextField")}/>
                            else
                                return <Input
                                    key={formElement.id}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    changed={event =>
                                        this.inputChangedHandler(
                                            event,
                                            formElement.id
                                        )
                                    }
                                    id_element = {formElement.config.elementType}
                                    invalid={!formElement.config.valid}
                                    shouldValidate={formElement.config.validation}
                                    touched={formElement.config.touched}                                
                                    />
                        
                                })}
                        <button disabled={!this.state.isPostEditFormValid} 
                        onClick={() => userPostEdit(this.state.postEditForm)}>
                            Submit
                        </button>
                    </form>
                    </Col>
                    </Row>
                </Container>
                </>
            );
        }
        //return this.props.loading ? 
        //    <Spinner />
        //) : (
        return(<div className={cssClass.OuterWrapper}>{form}</div>);
        
    }
}




export default (props) => (
    <PostEdit
        {...props}
        params={useParams()}
    />
);

