import React, { Component} from "react";


import { useParams } from 'react-router-dom';

import Row from "react-bootstrap/esm/Row";
import Badge from "react-bootstrap/Badge"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

import { FaNewspaper } from "react-icons/fa";
import { FaFacebookMessenger } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";

import Table from "react-bootstrap/esm/Table";

import axios, { AxiosResponse } from 'axios';

import CompanyCardPortfolioMini from "./tickerDataPortfolioChart";

function ConvertToCurrency(props) {

  // Nine Zeroes for Billions
  return Math.abs(Number(props.labelValue)) >= 1.0e+9

  ? (Math.abs(Number(props.labelValue)) / 1.0e+9).toFixed(2) + "B"
  // Six Zeroes for Millions 
  : Math.abs(Number(props.labelValue)) >= 1.0e+6

  ? (Math.abs(Number(props.labelValue)) / 1.0e+6).toFixed(2) + "M"
  // Three Zeroes for Thousands
  : Math.abs(Number(props.labelValue)) >= 1.0e+3

  ? (Math.abs(Number(props.labelValue)) / 1.0e+3).toFixed(2) + "K"

  : Math.abs(Number(props.labelValue));

}

function bookcost(props){

        var data_ = 0;
        var keys = [];
        var data = [];
        for (var key in props) {
            if (props.hasOwnProperty(key)) {
                keys.push(key);
                data.push(props[key]); // Not necessary, but cleaner, in my opinion. See the example below.
            }
        }
        console.log(data);
          for(var i = 0; i < data.length; i++){
            console.log(data[i].cost_paid);
            data_ += data[i].cost_paid;
          }
        console.log(data_)
          return(data_)
}

function removeTicker(tick){
    window.confirm('This ticker will be removed from your watchlist');
    alert(tick);
}

function PortfolioTable(props) {

    var data_ = [];
    var keys = [];
    var data = [];
    for (var key in props) {
        if (props.hasOwnProperty(key)) {
            keys.push(key);
            data.push(props[key]); // Not necessary, but cleaner, in my opinion. See the example below.
        }
    }
      for(var i = 0; i < data[0].length; i++){
        var ticker_ = data[0][i].hashtag;
        data_.push(
          <tr class idx>
            <td><CompanyCardPortfolioMini ticker={ticker_}/>
                
            </td>
            <td>{data[0][i].quantity_owned.toLocaleString()} @ ${data[0][i].average_price}</td>
            <td>${data[0][i].cost_paid.toLocaleString()}</td>
            <td><Badge variant="info"><FaFacebookMessenger/>{data[0][i].unread_posts}</Badge><Badge><FaNewspaper/>{data[0][i].unread_news}</Badge> <button onClick={() => removeTicker(ticker_)}><FaTrash/></button></td>
            
          </tr>
        );
      }
  
    return (
  
      <Table id="trades" variant="dark" striped response>
        <thead>
          <tr>
            <th></th>
            <th>Owned</th>
            <th>Cost</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{data_.map(d => (d))} </tbody>
      </Table>
    );
  }
  


class Portfolio extends Component {
    constructor(props) {
      super(props);
      this.state = {

        page_data: [],
        page_data_bool:false,
        page_data2: [],
        page_data_bool2:false,
        bookcost:0,

        };
    };


    async componentDidMount() {
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                AUTHORIZATION: "JWT " + access                
            },
        };
        
        axios.all([
            axios.get("/app/portfolio-data-get/", config)
        .then(response => {
            this.setState({page_data:response.data, page_data_bool:true}, ()=> this.setState({bookcost:bookcost(this.state.page_data)}));
        })
        .catch(error => {}),
        ]);
        
    };


    render(){
        return (
            this.state.page_data_bool? (
                <>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Watchlist Top 10{this.state.bookcost > 0? (<>- <b>${this.state.bookcost.toLocaleString()}</b></>):(<></>)}</Accordion.Header>
                        <Accordion.Body>
                        <PortfolioTable props={this.state.page_data}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                
                </>
            ):(<></>)
            
        )
    }

};

export default (props) => (
    <Portfolio
        {...props}
        params={useParams()}
    />
);
