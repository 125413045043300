import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import cssClass from "./PostCommentsList.css";
import Spinner from "../../../components/UI/spinner";
import { useParams } from 'react-router-dom';


import axios from "axios";
import AxiosInstance from "../../../utils/axiosBase";


// admin-panel/comments/list/
class AdPostCommentsList extends Component {
    constructor() {
        super();
        this.state = {
            commentList: null
        };
    }
    getAllComments = () => {
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "content-type": 'application/json',
                AUTHORIZATION: "JWT " + access
            }
        };
        AxiosInstance.get("/admin-panel/comments/list/"+this.props.params.slug+"/" , config)
        .then(response => {
            this.setState({commentList:response.data});
        })
        .catch(error => {
            alert(error);
        });
    };
    componentDidMount() {
        this.getAllComments();
    }

    commentDeleteHandler = pk => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + this.props.token
            }
        };

        let confirmation = window.confirm(
            "Do You Want To Delete This Comment?"
        );

        if (confirmation) {
            AxiosInstance.delete(
                "/admin-panel/comments/detail/" + pk + "/",
                config
            )
                .then(response => {
                    alert("Comment Deleted");
                    this.getAllComments();
                })
                .catch(error => {
                    alert("Something Went Wrong");
                });
        }
    };

    render() {
        let commentsList = this.props.allComments;
        if (this.state.commentList) {
            commentsList = this.state.commentList.map(comment => (
                <tr key={comment.id}>
                    <td>{comment.author_full_name}</td>
                    <td>{comment.body}</td>
                    <td>{new Date(comment.published_on).toDateString()}</td>
                    {comment.is_displayed ? (
                        <td style={{ color: "green" }}>Active</td>
                    ) : (
                        <td style={{ color: "red" }}>Not Active</td>
                    )}
                    <td>
                        <div className={cssClass.Actions}>
                            <Link
                                to={
                                    "/admin/view/comments-on-post/edit/" +
                                    comment.id +
                                    "/"
                                }
                            >
                                <button>Edit</button>
                            </Link>
                        </div>
                        <button
                            red
                            onClick={() => this.commentDeleteHandler(comment.id)}
                            identifier={comment.id}
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            ));
        }

        let commentsListTable = <Spinner />;

        commentsListTable = (
                <div className={cssClass.OuterWrapper}>
                    <table className={cssClass.Table}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Comment</th>
                                <th>Published On</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>{commentsList}</tbody>
                    </table>
                </div>
            );

        return (
            <>
                <div>
                    <div className={cssClass.Title}>Comments List</div>
                    {this.state.commentList ? commentsListTable : <Spinner />}
                </div>
            </>
        );
    }
}


export default (props) => (
    <AdPostCommentsList
        {...props}
        params={useParams()}
    />
);
