import React, { Component,useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosResponse } from 'axios';

import { useParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

import CoNewsContainer from "./helper/newsContainerCoPage";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Tabs from "react-bootstrap/esm/Tabs";
import Tab from "react-bootstrap/esm/Tab";
import Table from "react-bootstrap/esm/Table";
import Badge from 'react-bootstrap/Badge';
//import Badge from "react-bootstrap/esm/Badge";<Badge variant='success'>9</Badge>

import CommentCreate from "../components/CommentCreate";
import AxiosInstance from "../utils/axiosBase";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";

import Button from "react-bootstrap/esm/Button";

//charting
import { tsvParse, csvParse } from  "d3-dsv";
import { timeParse } from "d3-time-format";
import CandleChart from '../components/charts/Charts';

function convertUTCDateToLocalDate(date) {
  var d = new Date(date);
  var mins_ = d.getMinutes();
  if (mins_ == 0 | mins_ == 6){
      mins_ = '00'
  }
  var datestring = (d.getMonth()+1) +"-"+ d.getDate()  + "-" + d.getFullYear() + " " +d.getHours() + ":" + mins_;

  return datestring;
};

const parse = timeParse('%Y-%m-%d %H:%M:%S');

function parseData(data) {
	data.forEach(function (arrayItem) {
    console.log(arrayItem.date);
    console.log(parse(arrayItem.date));
    arrayItem.date = parse(arrayItem.date);
});
return(data);
}

class HashtagPage extends Component {
    constructor(props) {
      super(props);
      this.state = {

        hash_data: null,
        hash_data_bool:false,
        slug: this.props.params.slug,
        chartData:[],
        ticks_:false,
        tick_mention: [],
        page_data_bool:false,
        page_data:null
        };
    };


    async componentDidMount() {
        try {
          const session_url = `/app/hashcontent/${this.state.slug}`;

          var config = {
            method: 'get',
            url: session_url
          };

          var response = await axios(config);
          const hash_data = response.data;
          this.setState({hash_data:hash_data});
          if(this.state.hash_data != null){
            if(this.state.hash_data.body.length>0){
              this.setState({hash_data_bool : true});
            }
          }

          try{
            const session_url = `/comments/tick/count/${this.state.slug}/`;

            var config = {
                method: 'get',
                url: session_url
              };

            var response = await axios(config);
            var tick_mention = response.data;
            if(Object.keys(tick_mention).length>0){
                this.state.ticks_ = true;
                for(var key in tick_mention){
                    this.state.tick_mention.push({label: key, value: tick_mention[key]})
                }
              }
              var listItems = this.state.tick_mention.map((d) => <Badge key={d.label} bg="light"><a href={`/company/${d.label}`}>${d.label}  - {d.value} </a></Badge>);
              this.setState({listItems:listItems});
          }catch(e){};

          try{
            axios.all([
              AxiosInstance.get("/app/homepage-commodity-price/"+this.state.slug)
            .then(response => {
              var pd = response.data[0];
              console.log(pd)
              if(pd != undefined){
                this.setState({page_data:pd, page_data_bool:true});
              }
            })
            .catch(error => {
            }),
            axios
            .get("/app/chart-commodity-price/" + this.state.slug)
            .then(({ data }) => {
              if(data.length > 0){
                this.setState({chartData : parseData(data)}, ()=> console.log(this.state));
                this.state.chartDataLoading = true;
              }
            })
            .catch(err => {
              //alert(err);
              this.setState({ error: err.message })
            })])



          }catch(e){}


        }catch(e){
            console.log(e);
        }

      };
      sanitizeHtml = (dirty) => {
        return(sanitizeHtml(dirty, {
            allowedTags: ['b', 'i', 'p', 'strong', 'a'],
            allowedAttributes: {
              a: ['href']
            }
          }));
      };


render(){
 return (

    <div className="container">
          <Row>


              <Col sm={4}><h2>{this.state.slug} main page</h2>
              <Row>
                {this.state.page_data_bool ? (<Table>
                    <tr>
                      <td colspan="2" >Bid: ${parseFloat(this.state.page_data.bid).toFixed(2)} | Ask: ${parseFloat(this.state.page_data.ask).toFixed(2)}
                      {this.state.page_data.change > 0? (<Button variant={this.state.page_data.change > 0 ? 'outline-success' : 'outline-danger'}><FaArrowUp/>${parseFloat(this.state.page_data.change).toFixed(2)} | {parseFloat(this.state.page_data.changeprecent).toFixed(4)}% </Button>):(<Button variant={this.state.page_data.change > 0 ? 'outline-success' : 'outline-danger'}><FaArrowDown/>${parseFloat(this.state.page_data.change).toFixed(2)} | {parseFloat(this.state.page_data.changeprecent).toFixed(4)}% </Button>)}
                      </td>
                    </tr>
                    <tr>
                      <td>Volume: {this.state.page_data.tradevolume}</td>
                      <td>Open Interest: {this.state.page_data.openinterest}
                      </td>
                    </tr>
                    <tr><td>VWAP ${parseFloat(this.state.page_data.vwap).toFixed(4)}</td>
                    <td>Last Updated: {convertUTCDateToLocalDate(this.state.page_data.lastquotedatetime)}</td></tr>
                  </Table>):(<></>)}

                </Row>
              <Row>
              {this.state.chartData.length > 0 ? (<CandleChart type={'hybrid'} data={this.state.chartData} />):(<h3>No data! </h3>)}

                    {this.state.hash_data_bool? (
                        <>
                        <div dangerouslySetInnerHTML={{ __html:sanitizeHtml(this.state.hash_data.body)}}/></>
                    ):
                    (<></>)}
                </Row>
              <br />
                <Row>
                    Mentioned Companies:
                    <div>
                    {this.state.listItems}
                    </div>
                </Row>




              </Col>
              <Col sm={8}>
            <Tabs defaultActiveKey="general" className="mb-3">
              <Tab eventKey="general" title={
                <React.Fragment>
                Main

                </React.Fragment>
                } ><CommentCreate slug={this.state.slug}/>
              </Tab>

            </Tabs>
          </Col>
          </Row>

        <Row>



        </Row>
        <Row>



        </Row>


    </div>

  );
};
}

export default (props) => (
    <HashtagPage
        {...props}
        params={useParams()}
    />
);


