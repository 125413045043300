import React, { useRef, useEffect }  from 'react';

function Anchor({tag}, {setShowState}){

  const myRef = useRef();

  function scrollToComponent() {
    console.log(window.location.hash);
    console.log(tag);
    if (window.location.hash === tag) {
        setShowState = true;
      myRef.current.scrollIntoView();
      myRef.current.focus();
      
    }
  }

  useEffect( () => scrollToComponent(), [] )

  return (
    <div ref={myRef} id={tag}>
    </div>
  )
};

export default Anchor;