import React, { Component } from "react";
import AxiosInstance from "../utils/axiosBase";
import "./CommodityBar.css";
import Table from "react-bootstrap/esm/Table";
import { Container, Row, Button } from "react-bootstrap";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { GiGoldBar } from "react-icons/gi";
import {FaScroll} from "react-icons/fa";
import { MdOilBarrel } from "react-icons/md";
import { FaOilCan } from "react-icons/fa";
import { PiGasCanThin } from "react-icons/pi";
import { GiGasStove } from "react-icons/gi";
import { LuPiggyBank } from "react-icons/lu";
import { GiBullHorns } from "react-icons/gi";
import { GiMuscleUp } from "react-icons/gi";

import { TbCurrencyDollarAustralian } from "react-icons/tb";
import { TbCurrencyDollarCanadian } from "react-icons/tb";
import { TbCurrencyPeso } from "react-icons/tb";
import { TbCurrencyPound } from "react-icons/tb";
import { TbCurrencyReal } from "react-icons/tb";
import { TbCurrencyFrank } from "react-icons/tb";
import { TbCurrencyEuro } from "react-icons/tb";
import { TbCurrencyYen } from "react-icons/tb";

import { GiButter } from "react-icons/gi";
import './CommodityTable.css'

function returnButton(obj_){
    if(obj_.pagename=='Yen'){
        return(<div style={{ display: "flex", justifyContent: "center" }}>
    {obj_.change > 0? 
    (<Button align="center" variant={obj_.change > 0 ?
     'outline-success' : 'outline-danger'}>
        <FaArrowUp/>
        <p>${parseFloat(obj_.last).toFixed(6)}</p>
        ${parseFloat(obj_.change).toFixed(6)} | 
        {parseFloat(obj_.changeprecent).toFixed(4)}% 
        </Button>):(<Button variant={obj_.change > 0 ? 
            'outline-success' : 'outline-danger'}>
                <FaArrowDown/>
                <p>${parseFloat(obj_.last).toFixed(6)}</p>
                ${parseFloat(obj_.change).toFixed(6)} | 
                {parseFloat(obj_.changeprecent).toFixed(4)}% </Button>)}</div>)
    }else{
    return(<div style={{ display: "flex", justifyContent: "center" }}>
    {obj_.change > 0? 
    (<Button align="center" variant={obj_.change > 0 ?
     'outline-success' : 'outline-danger'}>
        <FaArrowUp/>
        <p>${parseFloat(obj_.last).toFixed(2)}</p>
        ${parseFloat(obj_.change).toFixed(2)} | 
        {parseFloat(obj_.changeprecent).toFixed(4)}% 
        </Button>):(<Button variant={obj_.change > 0 ? 
            'outline-success' : 'outline-danger'}>
                <FaArrowDown/>
                <p>${parseFloat(obj_.last).toFixed(2)}</p>
                ${parseFloat(obj_.change).toFixed(2)} | 
                {parseFloat(obj_.changeprecent).toFixed(4)}% </Button>)}</div>)
    }
}

class ComTab extends Component{
    constructor(props) {
        super(props);
        this.state = {
            comms: [],
            gold:[],
            silver:[],
            platinum:[],
            palladium:[],
            copper:[],
            aluminum:[],
            brent:[],
            crude:[],
            natty:[],
            gasoline:[],
            butter:[],
            cattle:[],
            leanhog:[],
            feeder:[],
            drywhey:[],
            aud:[],
            peso:[],
            real:[],
            cad:[],
            pound:[],
            peso:[],
            euro:[],
            franc:[],
            twoyus:[],
            fiveyus:[],
            tenyus:[],
            fiveycdn:[],
            tenycdn:[],
            sptsx60:[],
            hasData:false,
        };
    }
    async componentDidMount() {
        AxiosInstance.get("/app/homepage-commodity-price/periodic")
            .then(response => {
                //this.setState({comms:response.data, hasData:true});
                var comms = response.data;
                for(let i = 0; i<comms.length; i++){
                    console.log(comms[i])
                    if(comms[i].pagename == 'Gold'){
                        this.setState({gold:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Silver'){
                        this.setState({silver:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Platinum'){
                        this.setState({platinum:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Palladium'){
                        this.setState({palladium:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Copper'){
                        this.setState({copper:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Aluminum'){
                        this.setState({aluminum:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Brent'){
                        this.setState({brent:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Crude'){
                        this.setState({crude:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'NatGas'){
                        this.setState({natty:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Gasoline'){
                        this.setState({gasoline:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Cattle'){
                        this.setState({cattle:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Feeder'){
                        this.setState({feeder:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Leanhog'){
                        this.setState({leanhog:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Butter'){
                        this.setState({butter:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Drywhey'){
                        this.setState({drywhey:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Aud'){
                        this.setState({aud:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Loonie'){
                        this.setState({loonie:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Pound'){
                        this.setState({pound:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Euro'){
                        this.setState({euro:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Yen'){
                        this.setState({yen:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Real'){
                        this.setState({real:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Peso'){
                        this.setState({peso:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'Franc'){
                        this.setState({franc:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == '2YUST'){
                        this.setState({twoyus:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == '5YUST'){
                        this.setState({fiveyus:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == '10YUST'){
                        this.setState({tenyus:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == '5YCDNT'){
                        this.setState({fiveycdn:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == '10YCDNT'){
                        this.setState({tenycdn:comms[i], hasData:true})
                    }
                    if(comms[i].pagename == 'S&PTSX60'){
                        this.setState({sptsx60:comms[i], hasData:true})
                    }
                }
                
            })
            .catch(error => {
                this.setState({hasData:false});
            });
      };

  render() {
    if (this.state.hasData){
            return(
                <>

                <Container>
                    <Row>
                        <h3>TAStocks Periodic Table of Commodities</h3>
                    <Table>
                        <thead>
                            
                                <tr>
                                <th>Precious Metals</th>
                                <th>Base Metals</th>
                                <th>Energy</th>
                                <th>Food</th>
                                <th>Yields</th>
                                <th>FX</th>
                                <th>Indices</th>
                                </tr>
                            
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <h3><GiGoldBar color="gold"/>{this.state.gold.pagename}</h3>
                                {returnButton(this.state.gold)}
                            </td>
                            <td></td>
                            <td>
                                <h3><FaOilCan/>{this.state.brent.pagename}</h3>
                                {returnButton(this.state.brent)}
                            </td>
                            <td><h3><GiButter/>{this.state.butter.pagename}</h3>
                                {returnButton(this.state.butter)}</td>
                            <td><h3>{this.state.twoyus.pagename}</h3>
                                {returnButton(this.state.twoyus)}</td>
                            <td><h3><TbCurrencyDollarCanadian/> Cad</h3>
                                {returnButton(this.state.loonie)}</td>
                            <td><h3>S&PTSX60</h3>
                                {returnButton(this.state.sptsx60)}</td>
                        </tr>
                        <tr>
                            <td>
                                <h3><GiGoldBar color="grey"/>{this.state.silver.pagename}</h3>
                                {returnButton(this.state.silver)}
                            </td>
                            <td></td>
                            <td>
                                <h3><MdOilBarrel/>{this.state.crude.pagename}</h3>
                                {returnButton(this.state.crude)}
                            </td>
                            <td><h3><GiMuscleUp/>{this.state.drywhey.pagename}</h3>
                                {returnButton(this.state.drywhey)}</td>
                            <td><h3>{this.state.fiveyus.pagename}</h3>
                                {returnButton(this.state.fiveyus)}</td>
                            <td><h3><TbCurrencyDollarAustralian/> {this.state.aud.pagename}</h3>
                                {returnButton(this.state.aud)}</td>
                            <td></td>

                        </tr>
                        <tr>
                        <td>
                                <h3><GiGoldBar color="grey"/>{this.state.platinum.pagename}</h3>
                                {returnButton(this.state.platinum)}
                            </td>
                            <td>
                                <h3><GiGoldBar color="orange"/>{this.state.copper.pagename}</h3>
                                {returnButton(this.state.copper)}
                            </td>
                                <td>
                                    <h3><GiGasStove/>{this.state.natty.pagename}</h3>
                                    {returnButton(this.state.natty)}
                                </td>
                            <td><h3><GiBullHorns/>{this.state.cattle.pagename}</h3>
                                {returnButton(this.state.cattle)}</td>
                            <td><h3>{this.state.tenyus.pagename}</h3>
                                {returnButton(this.state.tenyus)}</td>
                            <td><h3><TbCurrencyYen/> {this.state.yen.pagename}</h3>
                                {returnButton(this.state.yen)}</td>
                            <td></td>
                            </tr>
                        <tr>
                                <td>
                                    <h3><GiGoldBar color="grey"/>{this.state.palladium.pagename}</h3>
                                    {returnButton(this.state.palladium)}
                                </td>
                                <td><h3><FaScroll color="grey"/>{this.state.aluminum.pagename}</h3>
                                {returnButton(this.state.aluminum)}
                                </td>
                                <td>
                                    <h3><PiGasCanThin/>{this.state.gasoline.pagename}</h3>
                                    {returnButton(this.state.gasoline)}
                                </td>
                                <td><h3><LuPiggyBank/>{this.state.leanhog.pagename}</h3>
                                {returnButton(this.state.leanhog)}</td>
                            <td><h3>{this.state.fiveycdn.pagename}</h3>
                                {returnButton(this.state.fiveycdn)}</td>
                            <td><h3><TbCurrencyEuro/> {this.state.euro.pagename}</h3>
                                {returnButton(this.state.euro)}</td>
                            <td></td>
                                
                            </tr>
                        <tr></tr>
                        <tr></tr>
                        </tbody>
                    </Table>
                    </Row>
                </Container>
                </>
                
            )
        }
    else{
        return(<></>)
    }
    
  };
  
};

export default ComTab;