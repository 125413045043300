import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CardGroup from 'react-bootstrap/CardGroup';
import React, { Component } from "react";
import companyLogoBlk from '../img/logo-black.jpeg';

import axios from "axios";
import { Link } from 'react-router-dom';
import AxiosInstance from "../utils/axiosBase";

import InfiniteScroll from "react-infinite-scroll-component";
import { FaPlusCircle } from 'react-icons/fa';



const styles = {
    card: {
      backgroundColor: '#B9E0F8',
      borderRadius: 60,
      padding: '4rem'
    },
    cardImage: {
        width: '100%',
        height: '12vw',
        objectfit: 'scale'
    }
  }

class NewsSquares extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            endMessage: "That's All!",
            moreContent: true,
            nextPage: "", 
            count: this.props.count
    };
    }



    async componentDidMount() {
        
        
        AxiosInstance.get("/post/")
            .then(response => {
                this.setState({response:response, posts: response.data.results, nextPage: response.data.next}, () => 
                    this.state.nextPage != null ? this.setState({moreContent:true}) : this.setState({moreContent:false}),
                    this.state.nextPage != '' ? this.setState({moreContent:true}) : this.setState({moreContent:false}));

                
            if(this.props.count){
                this.state.posts = this.state.posts.slice(0, this.props.count);
            }
            })
            .catch(error => {
                
            });
            
      };

        fetchMoreData = () => {
            if(this.state.moreContent){
                if(this.state.nextPage != null){
                    try{
                        fetch(this.state.nextPage).then(response => {response.json().then(res =>{
                            this.setState({nextPage: res.next});
                            this.setState({ posts: [...this.state.posts, ...res.results] });
                    })})
                        .catch((err) => {
                            this.setState({moreContent: false})
                        })
                    }catch (err){
                        this.setState({moreContent: false})
                    }
                }
                else{
                    this.setState({moreContent: false})
                }
            }
          };


    render() {
        

        return( this.state.count? (
                <>
                    {this.state.posts.slice(0,this.state.count).map((item, idx) =>{
                            return(
                                
                                <Col className="sm-2">
                                        <Card style={{ width: '22rem' }} key={idx}>
                                        
                                    <Card.Body>
                                        <Card.Title><Link to={"/post/view/"+item.slug}>{item.title}</Link></Card.Title>
                                        <Card.Text>
                                            {item.short_description}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                    <small className="text-muted">{item.author_full_name} {new Date(
                                                    item.published_on
                                                ).toDateString()}</small>
                                    </Card.Footer>
                                </Card>
                                </Col>
                            )})}
                </>)
                :(
            <>
            <div id="divposts" style={{height:"700px", overflow:'auto'}} ref={(ref) => this.scrollParentRef = ref}>
                
                    <InfiniteScroll
                    dataLength={this.state.posts.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.moreContent}
                    loader={this.state.moreContent ? (<h4>Loading...</h4>):(<></>)}
                    scrollableTarget="divposts"
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                          <b>You have seen it all ~</b>
                        </p>
                      }
                >
                        <Row>
                        {this.state.posts.map((item, idx) =>{
                            return(
                                
                                <Col key={idx} lg={3} md={4} sm={6} xs={12} className="mb-4">
                                        <Card style={{ width: 'auto' }} key={idx}>
                                        
                                    
                                    <Card.Body>
                                        <Card.Title><Link to={"/post/view/"+item.slug}>{item.title}</Link></Card.Title>
                                        <Card.Text>
                                            {item.short_description}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                    <small className="text-muted">{item.author_full_name} {new Date(
                                                    item.published_on
                                                ).toDateString()}</small>
                                    </Card.Footer>
                                </Card>
                                </Col>
                            )
                                    
                        })
                        }
                        </Row>
                    
                
                    </InfiniteScroll>
        </div>
        </>)
        
        )
    };
}


export default NewsSquares;