import React from "react";
import  {Component, useState, useRef} from "react";
import { FaLink as LinkIcon} from "react-icons/fa";
import Overlay from 'react-bootstrap/Overlay';


import axios, { AxiosResponse } from 'axios';

import { Link } from "react-router-dom";

import { FaRegNewspaper } from 'react-icons/fa';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'
import { useParams } from 'react-router-dom';

import InfiniteScroll from "react-infinite-scroll-component";
import AxiosInstance from "../../utils/axiosBase";

function convertUTCDateToLocalDate(date) {
    var d = new Date(date);
    var mins_ = d.getMinutes();
    if (mins_ == 0){
        mins_ = '00'
    }
    var datestring = (d.getMonth()+1) +"-"+ d.getDate()  + "-" + d.getFullYear() + " " +d.getHours() + ":" + mins_;

    return datestring;   
};

function CopyLink({item}){
    const [showAlert, setShowAlert] = useState(false);
    const target = useRef(null);

    return(
    <>
    <Button variant="info" ref={target} onClick={() => {
        navigator.clipboard.writeText(window.location.origin+'/news/'+item.title_slug,
         setShowAlert(!showAlert),
         setTimeout( () => {setShowAlert(showAlert)}, 1000)
    )}}>
    <LinkIcon/>
    </Button>
    <Overlay target={target.current} show={showAlert} placement="top">
    {({
    placement: _placement,
    arrowProps: _arrowProps,
    show: _show,
    popper: _popper,
    hasDoneInitialMeasure: _hasDoneInitialMeasure,
    ...props
    }) => (
    <div
        {...props}
        style={{
        position: 'absolute',
        backgroundColor: 'rgba(65, 239, 99, 0.37)',
        padding: '2px 10px',
        color: 'white',
        borderRadius: 3,
        ...props.style
        }}
    >
        Copied!
    </div>
    )}
    </Overlay>
    </>)
}

class CoNewsContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        ticker: this.props.ticker,
        moreContent: true,
        nextPage:null,
        tl: []
        };
    }
     
      async componentDidMount() {
        AxiosInstance.get('/app/news-list/'+this.state.ticker)
            .then(response => {
                
                //console.log(response)
                this.setState({tl: response.data.results});
                this.setState({nextPage:response.data.next})
                //console.log(this.state.nextPage)

                this.state.nextPage!=null? this.setState({moreContent:true}) : this.setState({moreContent:false});
                
            })
            .catch(error => {
                
                
            });
            
      };

      
      fetchMoreData= () => {
        if(this.state.moreContent){
            if(this.state.nextPage != null){
                try{
                    fetch(this.state.nextPage).then(response => {response.json().then(res =>{
                        this.setState({nextPage: res.next});
                        this.setState({ tl: [...this.state.tl, ...res.results] });
                })})
                    .catch((err) => {
                        this.setState({moreContent: false})
                    })
                }catch (err){
                    this.setState({moreContent: false})
                }
            }
            else{
                this.setState({moreContent: false})
            }
        }
      };
              
      render(){
        return(   
        
            
                <div id="tldiv" style={{height:"500px", overflow:'auto'}} ref={(ref) => this.scrollParentRef = ref}>
                <InfiniteScroll
                        dataLength={this.state.tl.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.moreContent}
                        loader={this.state.moreContent ? (<h4>Loading...</h4>):(<></>)}
                        scrollableTarget="tldiv"
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                              <b>You have seen it all ~</b>
                            </p>
                          }
                    >
                {this.state.tl.map((item, index) => {
                    return(
                    <Card style={{ width: 'auto' }} key={index}>
                        <Card.Body>
                        <Card.Text> 
                        <Link onClick={this.props.handler} to={`${item.title_slug}`}><u>{item.title}</u></Link>
                            </Card.Text>
                            <Card.Text>
                                <Table>
                                    <tr>
                                        <td>
                                            Released at; <i>{convertUTCDateToLocalDate(item.pubdate)}</i>
                                        </td>
                                        <td>
                                        <span className="d-none d-md-block"><Link to={`/news/${item.title_slug}`}>
                                                <Button variant="primary"><FaRegNewspaper /></Button>
                                            </Link></span>
                                            <CopyLink item={item}/>
                                        </td>
                                    </tr>
                                </Table>
                            </Card.Text>
                            
                        </Card.Body>
                    </Card>)
                    
                    })
                }
                </InfiniteScroll></div>
        )
  
    };
}

    export default (props) => (
        <CoNewsContainer
            {...props}
            params={useParams()}
        />
    );