import  {Component, useState, useRef} from "react";
import Col from 'react-bootstrap/Col';


import AxiosInstance from "../../utils/axiosBase";
import { useParams } from 'react-router-dom';


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Accordion from "react-bootstrap/Accordion";



function ConvertToCurrency(props) {

  // Nine Zeroes for Billions
  return Math.abs(Number(props.labelValue)) >= 1.0e+9

  ? (Math.abs(Number(props.labelValue)) / 1.0e+9).toFixed(2) + "B"
  // Six Zeroes for Millions 
  : Math.abs(Number(props.labelValue)) >= 1.0e+6

  ? (Math.abs(Number(props.labelValue)) / 1.0e+6).toFixed(2) + "M"
  // Three Zeroes for Thousands
  : Math.abs(Number(props.labelValue)) >= 1.0e+3

  ? (Math.abs(Number(props.labelValue)) / 1.0e+3).toFixed(2) + "K"

  : Math.abs(Number(props.labelValue));

}


class TickerDataMiniPortfolio extends Component{
    constructor(props) {
        super(props);
        this.state = {
            comms: [],
            hasData:false,
            count:0,
            price:0,
            ticker:this.props.ticker
        };
    }
    async componentDidMount() {

            const access = localStorage.getItem("access");
            const config = {
                headers: {
                    "Content-Type": "application/json", 
                    AUTHORIZATION: "JWT " + access
                    
                }
            };
            if(access){
                this.state.isAuthenticated = true;
            }
            
    };
        

    handleChangePrice(event){
        this.setState({'price':event.target.value})
    };
    
    handleChangeCount(event){
        this.setState({'count':event.target.value})
    };

    onFormSubmitEventHandler = event => {
        event.preventDefault();
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                AUTHORIZATION: "JWT " + access                
            },
        };
        const data = {
            'count':this.state.count,
            'price':this.state.price
        }
        AxiosInstance.post("/app/portfolio-data/"+this.state.ticker, data, config)
        .then((response) => {
            alert('Added!');
            window.location.reload();
        }).catch((error) => {
                if (error.response.status === 401) {
                  window.location = '/signup';
                }
        })
    };

    render(){

    if (this.state.isAuthenticated){
        return(<>
            <Accordion className="accordian" defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Add to Portfolio</Accordion.Header>
                    <Accordion.Body>
                        <Form noValidate validated={''} onSubmit={this.onFormSubmitEventHandler} method="POST">
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4" controlId="">
                                <Form.Label>Price</Form.Label>
                                <Form.Control
                                    name='price'
                                    required
                                    type="decimal"
                                    defaultValue={this.state.price} onChange={this.handleChangePrice.bind(this)}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="">
                                <Form.Label>Share Count</Form.Label>
                                <Form.Control
                                name='count'
                                    required
                                    type="number"
                                    defaultValue={this.state.count} onChange={this.handleChangeCount.bind(this)}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                
                            </Row>
                            <Button type="submit" >Update</Button>
                        </Form>
                        </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>)
    }
    else{
        return(<><Row><a href="/signup" align="center">Sign Up to Track to Your Portfolio</a></Row></>)
    }
    }
}


export default (props) => (
    <TickerDataMiniPortfolio
        {...props}
        params={useParams()}
    />
);
