import React, { Component,useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosResponse } from 'axios';

import { useParams } from 'react-router-dom';

import CoNewsContainer from "./helper/newsContainerCoPage";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Tabs from "react-bootstrap/esm/Tabs";
import Tab from "react-bootstrap/esm/Tab";
import Table from "react-bootstrap/esm/Table";

import CommentCreate from "../components/CommentCreateProfile";

import PostSquaresUser from "../components/PostSquaresUser";

class UserProfileView extends Component {
    constructor(props) {
      super(props);
      this.state = {

        userSlug: this.props.params.slug,
        user_data:true,
        user_data_chk:false
    };
    }


      async componentDidMount() {
        try {
          const session_url = `/admin-panel/users/detail/${this.state.userSlug}/us/`;

          var config = {
            method: 'get',
            url: session_url
          };

          var response = await axios(config);
          const user_data = response.data;
          this.setState({user_data:user_data});
          this.setState({user_data_chk:true});


        } catch (e) {
          console.log(e);
        }
      };


render(){
 return (
  this.state.user_data_chk ? (
    <div className="container">
        <Row> 
            <Col>
                {this.state.userSlug}  
                <p>Handle: {this.state.user_data.claimed_handle}</p>
                <p>Bio: {this.state.user_data.bio}</p>  
                <br></br>
                <h4>Posts</h4>
                <PostSquaresUser slug={this.state.userSlug}/>
            </Col>  
            <Col>
            <CommentCreate slug={'@'+this.state.userSlug}/>
            </Col>
        </Row>
          

    </div>
    ):
    (<></>)
  );
};
}

export default (props) => (
    <UserProfileView
        {...props}
        params={useParams()}
    />
);


