import React, { Component} from "react";


import { useParams } from 'react-router-dom';

import Row from "react-bootstrap/esm/Row";
import Badge from "react-bootstrap/Badge"
import Button from 'react-bootstrap/Button';

import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import { FaFire } from "react-icons/fa";

import Table from "react-bootstrap/esm/Table";

import AxiosInstance from "../../utils/axiosBase";


function ConvertToCurrency(props) {

  // Nine Zeroes for Billions
  return Math.abs(Number(props.labelValue)) >= 1.0e+9

  ? (Math.abs(Number(props.labelValue)) / 1.0e+9).toFixed(2) + "B"
  // Six Zeroes for Millions 
  : Math.abs(Number(props.labelValue)) >= 1.0e+6

  ? (Math.abs(Number(props.labelValue)) / 1.0e+6).toFixed(2) + "M"
  // Three Zeroes for Thousands
  : Math.abs(Number(props.labelValue)) >= 1.0e+3

  ? (Math.abs(Number(props.labelValue)) / 1.0e+3).toFixed(2) + "K"

  : Math.abs(Number(props.labelValue));

}


class CompanyCard extends Component {
    constructor(props) {
      super(props);
      this.state = {

        page_data: [],
        page_data_bool:false,

        };
    };


    async componentDidMount() {
        
        AxiosInstance.get("/app/ticker-data/"+this.props.ticker)
        .then(response => {
            this.setState({page_data:response.data, page_data_bool:true}, ()=> console.log(this.state));
        })
        .catch(error => {})
        
    };


    render(){
        return (
            this.state.page_data? (

                <>
                <h2>{this.state.page_data.longname} - ${this.props.ticker}  
                </h2>
                
                <Row>
                <Table>
                    <tr>
                        <td><b>{this.state.page_data.exShName}</b></td>
                        <td> MCAP <b>$<ConvertToCurrency labelValue={this.state.page_data.marketcap}/></b></td>

                        
                    </tr>
                    <tr>
                        <td>{this.state.page_data.sector}, {this.state.page_data.industry}</td>
                        <td> O/S: <b><ConvertToCurrency labelValue={this.state.page_data.sharesoutstanding}/></b></td>
                    </tr>
                    <tr>
                        <td>
                            
                            {
                            parseFloat(this.state.page_data.avgvol30days) < parseFloat(this.state.page_data.sharevolume)?
                             (<Button variant="outline-success" style={{float: 'left'}}><FaFire/>
                             Vol {this.state.page_data.sharevolume ? (<>{this.state.page_data.sharevolume.toLocaleString()}</>):(<></>)}</Button>):(<Button variant="outline-dark" style={{float: 'left'}}>{this.state.page_data.sharevolume ? (<>Vol {this.state.page_data.sharevolume.toLocaleString()}</>):(<></>)}</Button>)
                             }

                            </td>
                        <td>{this.state.page_data.avgvol30days ? (<Button variant="outline-dark" style={{float: 'left'}}>Avg Vol {this.state.page_data.avgvol30days.toLocaleString()}</Button>):(<>No data</>)}</td>
                    </tr>
                    <tr>
                        <td>
                                <div>
                                {this.state.page_data.rawbidsize? 
                                 (<Button style={{float: 'left'}} variant="outline-dark">BID:  
                                 {this.state.page_data.rawbidsize.toLocaleString()} - ${this.state.page_data.bid}</Button>):(<></>)}<> </>
                                 {this.state.page_data.rawasksize? 
                                 (<Button style={{float: 'right'}} variant="outline-dark">ASK:  
                                 {this.state.page_data.rawasksize.toLocaleString()} - ${this.state.page_data.ask}</Button>):(<></>)}</div>
                        </td>
                        <td>{this.state.page_data.changepercent >= 0? (<><Button style={{float: 'left'}} variant="outline-success">Last ${this.state.page_data.last}</Button></>):(<><Button style={{float: 'left'}} variant="outline-error">Last ${this.state.page_data.last}</Button></>)}
                        {this.state.page_data.changepercent >= 0? 
                (<><Button variant="outline-success"><FaArrowUp/>{Math.round(this.state.page_data.changepercent*100)/100}%</Button></>):(<><Button variant="outline-error"><FaArrowDown/>{Math.round(this.state.page_data.changepercent*100)/100}%</Button></>)}
                        </td>
                    </tr>
                    
                </Table>
                </Row>
                </>

            ):(<></>)
            
        )
    }

};

export default (props) => (
    <CompanyCard
        {...props}
        params={useParams()}
    />
);
