// Post List For Dashboard

import React, { Component } from "react";
import { Link } from "react-router-dom";

//import cssClass from "./PostList.css";
import cssClass from "./CreatePost.css";
import Spinner from "../../components/UI/spinner";
import { connect } from "react-redux";

import * as actions from "../Actions/actions.js";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import axios from "axios";
import AxiosInstance from "../../utils/axiosBase";




class PostList extends Component {
    constructor() {
        super();
        this.state = {
            userPostsList: ""
        };
    }

    getPostsList = () => {
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "content-type": 'application/json',
                AUTHORIZATION: "JWT " + access
            }
        };
        AxiosInstance.get("/dashboard/list-post/", config)
        .then(response => {
            this.setState({userPostsList:response.data});
        })
        .catch(error => {
            alert(error);
        });
    };

    componentDidMount() {
        this.getPostsList();
    }



    postDeleteHandler = slug => {
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            },
            data: {
                slug: slug
            }
        };

        console.log(slug);
        console.log(access);
        let confirmation = window.confirm("Do You Want To Delete This Post?");


        if (confirmation) {
            AxiosInstance.delete("/dashboard/delete-post/", config)
                .then(response => {
                    alert("Post Deleted");
                    this.getPostsList();
                })
                .catch(error => {
                    alert("Something Went Wrong");
                    this.getPostsList();
                });
        }
    };

    render() {
        let postsList = this.state.userPostsList
            ? this.state.userPostsList.map(post => (
                  <tr key={post.slug}>
                      <td>{post.title}</td>
                      <td>{post.total_comments}</td>
                      <td>{new Date(post.created_on).toDateString()}</td>
                      <td>
                          {post.is_published ? (
                              <span style={{ color: "green" }}>Published</span>
                          ) : (
                              <span style={{ color: "red" }}>
                                  Not Published Yet
                              </span>
                          )}
                      </td>
                      {!post.is_published ? (
                          <td>
                              <div className={cssClass.Actions}>
                                  <Link to={"/post/edit/" + post.slug + "/"}>
                                      <Button variant="outline-warning">Edit</Button>
                                  </Link>
                              </div>
                              <Button variant="outline-danger"
                                  onClick={() => this.postDeleteHandler(post.slug)}
                              >
                                  Delete
                              </Button>
                          </td>
                      ) : (
                          <td>
                              <Button variant="outline-danger"
                                  onClick={() => this.postDeleteHandler(post.slug)}
                                  identifier={post.slug}
                              >
                                  Delete
                              </Button>
                          </td>
                      )}
                  </tr>
              ))
            : null;

        let userPostsListTable = <Spinner />;

        if (this.state.userPostsList) {
            userPostsListTable = (
                <Container>
                    <Row>
                        <Col>
                        <h3>Posts List</h3>
                <div className={cssClass.OuterWrapper}>
                    <table className={cssClass.Table}>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Total Comments</th>
                                <th>Date Created</th>
                                <th>Status</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>{postsList}</tbody>
                    </table>
                </div>
                </Col></Row></Container>
            );
        }

        return (
            <>
                <div>{userPostsListTable}</div></>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        //userPostsList: state.post.userPostsList
        //loading: state.post.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onListPostsToUserDashboard: config =>
            dispatch(actions.listPostsToUserDashboard(config))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PostList);
