import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { checkAuthenticated, loadUser, logout } from "../store/auth";

import companyLogoWht from '../img/logo-white.jpg';

import "./Navbar.css";
import GetUser from "./UserLoggedIn";
import { useState } from "react";
import { BackButton } from "../pages/helper/backButton";
import Button from 'react-bootstrap/Button';


import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Search from './Search';

import ComNav from "./CommodityBar";

import { createContext } from 'react';
// this needs to be made into a component?




const Navbar_ = () => {
  const dispatch = useDispatch();




  var { isAuthenticated } = useSelector((state) => state.auth);
  var UserStatusContext = createContext(isAuthenticated);

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  useEffect(() => {
    dispatch(checkAuthenticated());
    isAuthenticated && dispatch(loadUser());
  }, [dispatch, isAuthenticated]);

  const handleLogout = () => {
    dispatch(logout());
    var UserStatusContext = createContext(false);
    window.location.reload();

  };


  const username = <GetUser/>;
  return (
    <>

    {['false'].map((expand) => (
      <>


      <Navbar key={expand} expand={expand} className="bg-body-tertiary">
      <Container fluid>
            <Nav>

                <Nav.Link eventKey={2} align="left">
                  <BackButton/>
                </Nav.Link>
            </Nav>
            <Nav>
                    <Search/>
            </Nav>

            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="ml-auto"/>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >

            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              <Link to="/">
                TAStocks
                </Link>
              </Offcanvas.Title>


            </Offcanvas.Header>


              <Offcanvas.Body>
              <Nav.Link to="/">
                      <div className="logo">
                        <img src={companyLogoWht} className="mx-auto d-none d-sm-block"/>
                      </div>
                    </Nav.Link>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link variant="outline-info" href="/page/newsroom">Newsroom</Nav.Link>
                <Nav.Link variant="outline-info" href="/trending">Trending</Nav.Link>
                <Nav.Link variant="outline-info" href="/chats">All Chats</Nav.Link>
                <Nav.Link variant="outline-info" href="/posts">Posts</Nav.Link>
                {isAuthenticated ? (
                                        <>
                                        <GetUser/>
                                        <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                                        </>
                                        ) : (
                      <>
                        <Nav.Link variant="outline-primary"href="/login">Login</Nav.Link>
                        <></>
                        <Nav.Link variant="outline-info" href="/signup">Sign Up</Nav.Link>
                      </>
                    )}

                </Nav>

              </Offcanvas.Body>

            </Navbar.Offcanvas>
          </Container>
          </Navbar>
          </>
          ))}
    </>

  );
};

export default Navbar_;
