import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";



const CreateAccount = props => {
    return (
        <>
          <h1 className="text-body-emphasis" align="center">Beat the herd.</h1>

          <p className="col-lg-8 mx-auto fs-5 text-muted" align="center">
            Create an Account for Full Access
          </p>
            <br></br>

            <Row className="justify-content-md-center">
              <Col xs lg="2">
                
              </Col>
              <Col md="auto">
                <Link
                className="d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill"
                to="/login"
              >
                Sign In
              </Link>
              <Link
                className="btn btn-outline-secondary btn-lg px-4 rounded-pill"
                to="/signup"
              >
                Sign Up
              </Link>
              </Col>
              <Col xs lg="2">
                
              </Col>
            </Row>
          </>)};

export default CreateAccount;