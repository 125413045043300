import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';


import Col from 'react-bootstrap/Col';
import React from "react";

import AxiosInstance from "../../utils/axiosBase";
import Button from 'react-bootstrap/esm/Button';
import { useSelector } from "react-redux";
import CreateAccount from "../../components/CreateAccount";



function currencyFormat(number) {
    // Use the toLocaleString method to add suffixes to the number
    return number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      notation: 'compact',
      compactDisplay: 'short'
    });
  }


const Filter = ({parentCallback}) => {
    var state = {
        sector: null,
        exchange: null, 
        news: null,
        mcap_low: null,
        mcap_high: null,
        results: [],
        called:false,
        min_value:null,
        max_value:null,
        min_vis_value:null,
        max_vis_value:null
    };
    

    const updateInputValue = (evt) => {
        
        
        
        const val = evt.target.value;
        const var_ = evt.target.id;
        
        
        state[var_] = val;
        state.called = false;

        if(evt.target.value != '' | parseFloat(evt.target.value) != 'NaN'){
            if(evt.target.id == 'min_field_mcap'){
                state.min_value = parseFloat(evt.target.value);
                state.mcap_low = state.min_value;
                state.min_vis_value = currencyFormat(parseFloat(evt.target.value));
            }if(evt.target.id == 'max_field_mcap'){
                state.max_value = parseFloat(evt.target.value);
                state.mcap_high = state.max_value;
                state.max_vis_value = currencyFormat(parseFloat(evt.target.value));
            }
        }
        
      }
  
    const search = () => {
        
        if(state.called==false){
            const params = new URLSearchParams(); 

            const arr = {'sector': state.sector,
            'exchange':state.exchange, 
            'news':state.news,
            'mcap_low':state.mcap_low,
            'mcap_high':state.mcap_high};
  
            for (const [key, value] of Object.entries(arr)) {
                params.append(key, value)
            }
            AxiosInstance.get("app/filter-list/", {params})
            .then(response => {
                parentCallback(response.data);
            }).catch(error => {
                //oops                
            });
        };
    };
  
  
    const { isAuthenticated } = useSelector((state) => state.auth);
        


      return (
        <>
            <Accordion>
                <Accordion.Item eventKey="0">
                <Accordion.Header>Advanced Filter</Accordion.Header>
                {isAuthenticated? (
                <Accordion.Body>
                    <Row>
                    <Col>
                    <Form.Label>Sector</Form.Label>
                        <Form.Select id='sector' aria-label="select sector" onChange={evt => updateInputValue(evt)}>
                        <option value='--'>--</option>
                        <option value='All'>All</option>
                        <option value='SPAC'>SPAC</option>
                        <option value='Mining'>Mining</option>
                        <option value='Utilities & Pipelines'>Utilities & Pipelines</option>
                        <option value='Real Estate'>Real Estate</option>
                        <option value='Consumer Products & Services'>Consumer Products & Services</option>
                        <option value='ETP'>ETP</option>
                        <option value='Life Sciences'>Life Sciences</option>
                        <option value='Oil & Gas'>Oil & Gas</option>
                        <option value='Clean Technology & Renewable Energy'>Clean Technology & Renewable Energy</option>
                        <option value='Closed-End Funds'>Closed-End Funds</option>
                        <option value='Financial Services'>Financial Services</option>
                        <option value='Industrial Products & Services'>Industrial Products & Services</option>
                        <option value='CPC'>CPC</option>
                        <option value='Comm. & Media'>Comm. & Media</option>
                        <option value='Technology'>Technology</option>
                        
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label>Market Cap  {'>='} <p><Form.Control id='min_field_mcap' value={state.min_value} type="text" onChange={evt => updateInputValue(evt)}/></p></Form.Label>
                        
                        <Form.Label>Market Cap  {'<='} <p><Form.Control id='max_field_mcap' value={state.max_value} type="text" onChange={evt => updateInputValue(evt)}/></p></Form.Label>
                        <p>Market Cap from: ${state.min_vis_value} -</p> <p>${state.max_vis_value}</p>
                    </Col>
                    <Col>
                        <Form.Label>News</Form.Label>
                        <Form.Select id='news' aria-label="select recent news" onChange={evt => updateInputValue(evt)}>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                        </Form.Select>
                        <Form.Label>Exchange</Form.Label>
                        <Form.Select id='exchange' aria-label="select exchange" onChange={evt => updateInputValue(evt)}>
                        <option value="All">All</option>
                        <option value="TSX">TSX</option>
                        <option value="TSXv">TSXv</option>
                        <option value="CSE">CSE</option>
                        
                        </Form.Select>
                    </Col>
                    </Row>
                    <Row>
                    <Button variant="info" onClick={search}>Reload</Button>{' '}
                    </Row>
                    </Accordion.Body>
                ):(<Accordion.Body><CreateAccount/></Accordion.Body>)}
                </Accordion.Item>
            </Accordion>
        </>
      )
}

export default Filter;