import React, { Component} from "react";


import { useParams } from 'react-router-dom';

import Row from "react-bootstrap/esm/Row";
import Badge from "react-bootstrap/Badge"
import Button from 'react-bootstrap/Button';

import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import { FaFire } from "react-icons/fa";

import Table from "react-bootstrap/esm/Table";

import AxiosInstance from "../../utils/axiosBase";
import axios, { AxiosResponse } from 'axios';

import CompanyCardMini from "./tickerDataMini";
import NewsSquares from "../../components/NewsSquares";

function ConvertToCurrency(props) {

  // Nine Zeroes for Billions
  return Math.abs(Number(props.labelValue)) >= 1.0e+9

  ? (Math.abs(Number(props.labelValue)) / 1.0e+9).toFixed(2) + "B"
  // Six Zeroes for Millions 
  : Math.abs(Number(props.labelValue)) >= 1.0e+6

  ? (Math.abs(Number(props.labelValue)) / 1.0e+6).toFixed(2) + "M"
  // Three Zeroes for Thousands
  : Math.abs(Number(props.labelValue)) >= 1.0e+3

  ? (Math.abs(Number(props.labelValue)) / 1.0e+3).toFixed(2) + "K"

  : Math.abs(Number(props.labelValue));

}


class Trending extends Component {
    constructor(props) {
      super(props);
      this.state = {

        page_data: [],
        page_data_bool:false,
        page_data2: [],
        page_data_bool2:false,

        };
    };


    async componentDidMount() {
        
        axios.all([
            axios.get("/app/trending-tickers/")
        .then(response => {
            this.setState({page_data:response.data, page_data_bool:true}, ()=> console.log(this.state));
        })
        .catch(error => {}),
         axios.get("/app/gains-tickers/")
        .then(response => {
            this.setState({page_data2:response.data, page_data_bool2:true}, ()=> console.log(this.state));
        })
        .catch(error => {})])
        
    };


    render(){
        return (
            this.state.page_data? (
                <>
                <Row>
                    <h3>Recent reads</h3>
                    <NewsSquares count={6} />
                </Row>
                <Row>
                    <h3>Popular in TAStocks</h3>
                    {this.state.page_data.map((i, v) => 
                        <CompanyCardMini ticker={this.state.page_data[v]} reason={'Trending'}/>
                    )}
                    </Row>
                
                    <Row>
                    <h3>Market Movers</h3>
                {this.state.page_data2.map((i, v) => 
                        <CompanyCardMini ticker={this.state.page_data2[v]} reason={'Anamoly'}/>
                    )}
                    </Row>
                </>
            ):(<></>)
            
        )
    }

};

export default (props) => (
    <Trending
        {...props}
        params={useParams()}
    />
);
