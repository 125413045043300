import React, { Component} from "react";


import { useParams } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";

import AxiosInstance from "../../utils/axiosBase";

import { FaFire } from "react-icons/fa";
import { FaEye } from "react-icons/fa";

function ConvertToCurrency(props) {

  // Nine Zeroes for Billions
  return Math.abs(Number(props.labelValue)) >= 1.0e+9

  ? (Math.abs(Number(props.labelValue)) / 1.0e+9).toFixed(2) + "B"
  // Six Zeroes for Millions 
  : Math.abs(Number(props.labelValue)) >= 1.0e+6

  ? (Math.abs(Number(props.labelValue)) / 1.0e+6).toFixed(2) + "M"
  // Three Zeroes for Thousands
  : Math.abs(Number(props.labelValue)) >= 1.0e+3

  ? (Math.abs(Number(props.labelValue)) / 1.0e+3).toFixed(2) + "K"

  : Math.abs(Number(props.labelValue));

}


class CompanyCardPortfolioMini extends Component {
    constructor(props) {
      super(props);
      this.state = {

        page_data: [],
        page_data_bool:false,

        };
    };


    async componentDidMount() {
        
        AxiosInstance.get("/app/ticker-data/"+this.props.ticker)
        .then(response => {
            this.setState({page_data:response.data, page_data_bool:true}, ()=> console.log(this.state));
        })
        .catch(error => {})
        
    };


    render(){
        return (
            this.state.page_data? (
                <>
                            <p> <span className="d-none d-md-block">{this.state.page_data.longname} - </span><a href={"/company/"+this.props.ticker}> ${this.props.ticker} - ${this.state.page_data.last}</a> 
                            
                            {this.state.page_data.changepercent >= 0? 
                                (<><FaArrowUp/> {Math.round(this.state.page_data.changepercent*100)/100}%</>):(<><FaArrowDown/>{Math.round(this.state.page_data.changepercent*100)/100}%</>)}</p>
                </>
                            
                        


            ):(<></>)
            
        )
    }

};

export default (props) => (
    <CompanyCardPortfolioMini
        {...props}
        params={useParams()}
    />
);
