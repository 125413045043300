import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Spinner from "../../../components/UI/spinner";
import cssClass from "./PostList.css";
import Button from 'react-bootstrap/Button';


import axios from "axios";
import AxiosInstance from "../../../utils/axiosBase";



class AdPostList extends Component {
    constructor() {
        super();
        this.state = {
            postList: null
        };
    }

    getAllPosts = () => {
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "content-type": 'application/json',
                AUTHORIZATION: "JWT " + access
            }
        };
        AxiosInstance.get("/admin-panel/posts/", config)
        .then(response => {
            this.setState({postList:response.data});
        })
        .catch(error => {
            alert(error);
        });
    };
    componentDidMount() {
        this.getAllPosts();
    }

    postDeleteHandler = slug => {
        const access = localStorage.getItem("access");

        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
        };

        let confirmation = window.confirm("Do You Want To Delete This Post?");

        if (confirmation) {
            AxiosInstance.delete(
                "/admin-panel/posts/view/" + slug + "/",
                config
            )
                .then(response => {
                    alert("Post Deleted");
                    this.getAllPosts();
                })
                .catch(error => {
                    alert("Something Went Wrong");
                });
        }
    };

    render() {
        let postsList = null;
        if (this.state.postList) {
            postsList = this.state.postList.map(post => (
                <tr key={post.slug}>
                    <td>{post.title}</td>
                    <td>{post.total_comments}</td>
                    <td>{post.author_full_name}</td>
                    {post.is_published ? (
                        <td style={{ color: "green" }}>Published</td>
                    ) : (
                        <td style={{ color: "red" }}>Not Published</td>
                    )}
                    <td>
                    <div className={cssClass.Actions}>
                                  <Link to={"/admin/view/post-edit/" + post.slug + "/"}>
                                      <Button variant="outline-warning">Edit</Button>
                                  </Link>
                              </div>
                              <Button variant="outline-danger"
                                  onClick={() => this.postDeleteHandler(post.slug)}
                              >
                                  Delete
                              </Button>
                    </td>
                    <td>
                        <Link to={"/admin/view/comments-on-post/" + post.slug+"/"}>
                            View Comments
                        </Link>
                    </td>
                </tr>
            ));
        }

        let postsListTable = <Spinner />;

        postsListTable = (
                <div className={cssClass.OuterWrapper}>
                    <table className={cssClass.Table}>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Total Comments</th>
                                <th>Author</th>
                                <th>Status</th>
                                <th>Actions</th>
                                <th>Comments</th>
                            </tr>
                        </thead>
                        <tbody>{postsList}</tbody>
                    </table>
                </div>
            );
        
        return (
            <>
                <div className={cssClass.Title}>All Posts</div>
                <div>{this.state.postList ? postsListTable : <Spinner />}</div>
            </>
        );
    }
}


export default AdPostList;
