import React, { Component, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AxiosInstance from "../utils/axiosBase";


import { useNavigate } from "react-router-dom";
//<Link to={{ pathname:'/comment/share/'+slug+'/'+item.slug, state: { background: location } }}>

const CommentModal = (props) => {
    const page_share = props.params.ticker;

    const [data, setData] = React.useState([])
    const [complete, setComplete] = React.useState([])

    useEffect(() => {

        AxiosInstance.get("/comments/single/" + props.params.slug + "/")
        .then((response)=>{
            setData(response.data);
            setComplete(true);
        })
        }, []);

  const closeModal = e => {
    e.stopPropagation();

    if(page_share.includes('@')){
    window.location = '/profile/'+page_share
    }
    if(page_share.includes('home')){
    window.location = '/'
    }
    else{
    window.location = '/company/'+page_share
    }

    }
    
  

  React.useEffect(() => {
    document.body.classList.add("overflow-hidden");

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
        {complete && 
      <Modal.Dialog>
        <Modal.Header closeButton onClick={closeModal}>
          <Modal.Title>Written by: {data.handle} at {data.published_on}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Rating: {data.rating}</p>
          <p>{data.body}</p>
          <p><i>Posted from {data.original} </i></p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Close</Button>
          <Button variant="primary">Copy Link</Button>
        </Modal.Footer>
      </Modal.Dialog>
      }
    </div>
    
  );
};

export default (props) => (
    <CommentModal
        {...props}
        params={useParams()}
    />
);