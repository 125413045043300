import React from "react";
import NewsSquares from "../components/NewsSquares";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';


const Posts = () => {
  

  return (
    
    <Container>
        <h2>All Posts</h2>
        <Row>
      <NewsSquares/>
      </Row>
    </Container>
    
  );
  
};

export default Posts;
