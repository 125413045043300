import React, { Component } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import AxiosInstance from "../../utils/axiosBase";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend, zoomPlugin
);



class L2Bar extends Component{
    constructor(props) {
        super(props);
        this.state = {
        show_l2 : false,
        
        ticker: this.props.ticker,
        data : this.props.data,
        dataOut:this.props.dataOut,

        options : {
            indexAxis: 'y',
            
            elements: {
                bar: {
                borderWidth: 2,
                },
            },
            
    
            responsive: true,
            plugins: {
                
                title: {
                    display: true,
                    text: 'Market Depth',
                },
                legend: {
                    display: false
                 },
                 tooltip: {
                    callbacks: {
                        label: function(context) {
                            //console.logg(context);
                            let label = context.dataset.volume || '';
    
                            if (label) {
                                label = [context.dataset.volume +' @ '+ context.dataset.price +' - From: '+context.dataset.house, 'Posted at: '+context.dataset.date];
                            }
                            return label
                      }
                    }
                  },
                  zoom: {
                    zoom: {
                      
                      pinch: {
                        enabled: true
                      },
                      mode: 'xy',
                    }
                  }
            },
            scales: {
                
                y: {
                stacked: true,
                display: true
                }
            }
            }
    }};

    
    render(){
        if(this.state.data.datasets.length >0){
        return <Bar options={this.state.options} data={this.state.data} /> ;
        }
        else{
            return <></>
        }
    }
}

export default L2Bar;

