import React, { Component,useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosResponse } from 'axios';

import { useParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

import CoNewsContainer from "./helper/newsContainerCoPage";
import NewsPage from "./NewsPage";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Tabs from "react-bootstrap/esm/Tabs";
import Tab from "react-bootstrap/esm/Tab";
import Table from "react-bootstrap/esm/Table";
import Accordion from 'react-bootstrap/Accordion';
//import Badge from "react-bootstrap/esm/Badge";<Badge variant='success'>9</Badge>

import CommentCreate from "../components/CommentCreate";
import CommentCreateManagement from "../components/CommentCreateManagement";

//new charting 
import CandleChart from '../components/charts/Charts';

// l2 
import L2Bar from "../components/charts/l2";
import CompanyCard from "./helper/tickerData";

//[share count]
import TickerDataMiniPortfolio from "./helper/tickerDataMiniPortfolio";


import { tsvParse, csvParse } from  "d3-dsv";
import { timeParse } from "d3-time-format";

function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}
const colors = {
  'S': 'red',
  'B': 'green'
}

function TradeTable(props) {
  //var json_data = JSON.parse(props.obj_);
  var text_ = props.obj_.jsondata.replace(/'/g, '"');;
  var json__ = JSON.parse(text_);
  //console.log(json__)
  var data_ = [];
    for(var i = 0; i < json__.trade.length; i++){
      //console.log(json__[i])
      data_.push(
        <tr class idx>
          <td>{json__.trade[i].timestamp}</td>
          <td>{json__.trade[i].price}</td>
          <td>{json__.trade[i].volume}</td>
          <td>{json__.trade[i].sellerid}</td>
          <td>{json__.trade[i].buyerid}</td>
        </tr>
      );
    }

  return (

    <Table id="trades" variant="dark" striped response>
      <thead>
        <tr>
          <th>Time</th>
          <th>Price</th>
          <th>Volume</th>
          <th>Seller</th>
          <th>Buyer</th>
        </tr>
      </thead>
      <tbody>{data_.map(d => (d))} </tbody>
    </Table>
  );
}

function L2Table(props) {
  var data_ = [];
  var sells_ = [];
  var buys_ = [];  
  var level_table = 0;
  for(var i = 0; i < props.obj_.length; i++){
    if(props.obj_[i].orderSide == 'S'){
      sells_.push(props.obj_[i])
    }
    else{
      buys_.push(props.obj_[i]);
    }
  }
  if (sells_.length >= buys_.length) {
    level_table = buys_.length;
  }
  else{
    level_table = sells_.length;
  }
  sells_.reverse();
  for(var i = 0; i<level_table; i++){
      data_.push(
        <tr class idx>
          
          
          <td>({sells_[i].count}) {buys_[i].marketMakerID} </td>
          <td>$ {round(buys_[i].cost, 2)}</td>
          <td>{buys_[i].size}</td>
          <td style={{ color: colors[buys_[i].orderSide] }}>{buys_[i].price}</td>
          <td style={{ color: colors[sells_[i].orderSide] }}>{sells_[i].price}</td>
          <td>{sells_[i].size}</td>
          <td>$ {round(sells_[i].cost, 2)}</td>          
          <td>{sells_[i].marketMakerID} ({sells_[i].count})</td>
          
        </tr>
      );
    }


  return (
    <Table id="table" variant="dark" striped response>
      <thead>
        <tr>
          <th>Count / MM</th>
          <th>Cost</th>
          <th>Size</th>
          <th>Price</th>
          <th>Price</th>
          <th>Size</th>
          <th>Cost</th>
          <th>MM / Count</th>

        </tr>
      </thead>
      <tbody>{data_.map(d => (d))} </tbody>
    </Table>
  );
}


function L2AggTable(props) {

  var data_ = [];
  var sells_ = [];
  var buys_ = [];  
  var level_table = 0;
  for(var i = 0; i < props.obj_.length; i++){
    if(props.obj_[i].orderSide == 'S'){
      sells_.push(props.obj_[i])
    }
    else{
      buys_.push(props.obj_[i]);
    }
  }
  if (sells_.length >= buys_.length) {
    level_table = buys_.length;
  }
  else{
    level_table = sells_.length;
  }
  sells_.reverse();
  for(var i = 0; i<level_table; i++){
      data_.push(
        <tr class idx>
          
          
          <td>({sells_[i].count}) - $ {round(buys_[i].cost, 2)}</td>
          
          <td>{buys_[i].size}</td>
          <td style={{ color: colors[buys_[i].orderSide] }}>{buys_[i].price}</td>

          <td style={{ color: colors[sells_[i].orderSide] }}>{sells_[i].price}</td>
          
          <td>{sells_[i].size}</td>

          <td>$ {round(sells_[i].cost, 2)} - ({sells_[i].count})</td>
          
        </tr>
      );
    }
    
  


  return (
    <Table id="table2" variant="dark" striped response>
      <thead>
        <tr>
          <th>Orders / Cost</th>
          <th>Size</th>
          <th>Price</th>
          <th>Price</th>
          <th>Size</th>
          <th>Cost / Orders</th>
        </tr>
      </thead>
      <tbody>{data_.map(d => (d))} </tbody>
    </Table>
  );
}


const parse = timeParse('%Y-%m-%d %H:%M:%S');

function parseData(data) {
	data.forEach(function (arrayItem) {
    //console.log(arrayItem.date);
    //console.log(parse(arrayItem.date));
    arrayItem.date = parse(arrayItem.date);
});
return(data);
}

function convertUTCDateToLocalDate(date) {
  var d = new Date(date);
  var mins_ = d.getMinutes();
  if (mins_ == 0){
      mins_ = '00'
  }
  if(mins_ < 10 & mins_ > 0){
      mins_ = '0'+String(mins_)
  }
  var datestring = (d.getMonth()+1) +"-"+ d.getDate()  + "-" + d.getFullYear() + " " +d.getHours() + ":" + mins_;

  return datestring;   
};



class CompanyPage extends Component {
    constructor(props) {
      super(props);
      this.isNewsHandler = this.isNewsHandler.bind(this)

      this.state = {

        company: null,
        newsslug_:this.props.params.slug,
        newsslug_bool:false,
        company_data:false,
        ticker: this.props.params.ticker,
        chartData:[],
        chartDataLoading:false,
        chartDataAgg:[],
        aggLoaded:false,
        chartDataAggSum:[],
        aggSumLoaded:false,
        tradeList:[],
        tradeData:false,
        tradeDateSave:null,
        activeKey:'general',
        data : {
          labels:[],
          datasets: []
        },
        dataOut:[],
        };
    };

    // componentDidUpdate(){
    //   if(this.state.newsslug_bool === true){
    //     this.setState({activeKey:'news', newsslug_:this.props.params.slug});
    //   }
    // }

    afterSetStateFinished(){
      
        axios.all([
        axios
        .get("/app/price-list/" + this.state.company.ticker_no_exchange)
        .then(({ data }) => {
          if(data.length > 0){
            this.setState({chartData : parseData(data)}, ()=> console.log(''));
            this.state.chartDataLoading = true;
          }
        })
        .catch(err => {
          //alert(err);
          this.setState({ error: err.message })
        }),
        axios
        .get("/app/depth-list-table-consolidated/" + this.state.company.ticker_no_exchange)
        .then(({ data }) => {
          if(data.length > 0){
            this.setState({chartDataAgg : data, aggLoaded:true});
            
          }
        })
        .catch(err => {
          //alert(err);
          this.setState({ error: err.message })
        }),
        axios
        .get("/app/trade-list/" + this.state.company.ticker_no_exchange)
        .then(({ data }) => {
            this.setState({tradeList : data, tradeData:true, tradeDateSave:data.insertdate});
        })
        .catch(err => {
          //alert(err);
          this.setState({ error: err.message })
        }),
        axios
        .get("/app/depth-list-table-sum-consolidated/" + this.state.company.ticker_no_exchange)
        .then(({ data }) => {
          if(data.length > 0){
            this.setState({chartDataAggSum : data, aggSumLoaded:true});
        }
        })
        .catch(err => {
          //alert(err);
          this.setState({ error: err.message })
        }),
        axios.get("/app/depth-list/" + this.state.ticker)
        .then(response => {
          var data_ = {
            labels:[],
            datasets: []
          };
          this.setState({dataOut: response.data}, () => console.log(''));
            for(var i = 0; i < response.data.length; i++) {
                data_.labels.push(response.data[i].price);
                data_.labels = [... new Set(data_.labels)].sort().reverse();

            }
            for(var i = 0; i < response.data.length; i++) {
                

                var index_ = data_.labels.indexOf(response.data[i].price);

                var tmp_ = [];
                for(var j = 0; j < data_.labels.length; j++) {
                    if (j == index_){
                        tmp_.push(response.data[i].size)
                    }else{
                        tmp_.push(0);
                    }
                }
                
                if (response.data[i].orderSide == 'B') {
                    // the index of the current price
                    

                    data_.datasets.push(
                        
                        {
                            label: response.data[i].id,
                            //order: 'Buy',
                            data: tmp_,
                            price: response.data[i].price,
                            volume: response.data[i].size,
                            date: response.data[i].lastUpdate,
                            house: response.data[i].marketMakerID,
                            borderColor: 'rgb(128,128,128)',
                            backgroundColor: 'rgba(90, 200, 132, 0.75)',
                            barThickness: 10,
                          }
                    )
                }
                if (response.data[i].orderSide == 'S') {
                    data_.datasets.push(
                        {
                            label: response.data[i].price,
                            //order: 'Sell',
                            data: tmp_,
                            price: response.data[i].price,
                            volume: response.data[i].size,
                            date: response.data[i].lastUpdate,
                            house: response.data[i].marketMakerID,
                            borderColor: 'rgb(128,128,128)',
                            backgroundColor: 'rgba(255, 99, 132, 0.75)',
                            barThickness: 10,
                          }
                    )
                }
            };
            this.setState({data:data_}, () => console.log(''))
            //this.state.buy = this.state.data.map(label => this.state.data['orderSide'].B);
            //this.state.sell = this.state.data.map(label => this.state.data['orderSide'].S);
            
            
        })
        .catch(error => {
            this.setState({moreContent:false});
            
        })])
      
    };

    async componentDidMount() {
      if(this.state.newsslug_ != '' && this.state.newsslug_ != 'undefined' && typeof this.state.newsslug_ != "undefined" ){
        //alert('validated');
        this.setState({
          newsslug_bool:true,
          
        }, () => this.setState({activeKey:'news'}))
      }
        try {
          const session_url = `/app/company/${this.state.ticker}`;

          var config = {
            method: 'get',
            url: session_url
          };

          var response = await axios(config);
          const company = response.data;
          this.setState({company:company}, () => {
            this.afterSetStateFinished();
          });
          this.setState({company_data:true});
          

        } catch (e) {
          //console.logg(e);
        }
      };
      sanitizeHtml = (dirty) => {
        return(sanitizeHtml(dirty, {
            allowedTags: ['b', 'i', 'p', 'strong', 'a'],
            allowedAttributes: {
              a: ['href']
            }
          }));
      };

  // handleSelect(key) {
  //   //console.logg(key)
  //   if (key === 'new'){
  //     this.setState({ activeKey: "news"}, () => 
  //       //console.logg(this.state.activeKey));
  //     //console.logg('hit');
  //   }if (key === 'general'){
  //     this.setState({newsslug_:null, newsslug_bool:false, activeKey:'general'})
  //   }if (key === 'management'){
  //     this.setState({ activeKey: "management" });
  //   }else{
  //     this.setState({ activeKey: "general" });
  //   }//console.logg(this.state)
  // }
  isNewsHandler() {
      if(this.props.params.slug != '' ){
        
        this.setState({
          newsslug_bool:true,
          activeKey:'news'        
        })
    }
  };
  backToAllNews() {
    if(this.state.newsslug_ != '' && this.state.newsslug_ != 'undefined' && typeof this.state.newsslug_ != "undefined" ){
      
      this.setState({
        newsslug_bool:false,
        activeKey:"news"        
      })
    }
  };

      

render(){
 return (
  this.state.company_data ? (
    <div className="container">
          <Row> 
              <Col>
                  
                  <Row>
                    <CompanyCard ticker={this.state.company.ticker_no_exchange}/>
                    
                  </Row>
                  <Row>
                  <Tabs fill>
                    <Tab eventKey="Chart" title="Chart">{this.state.chartData.length > 0 ? (<CandleChart type={'hybrid'} data={this.state.chartData} />):(<h3>No data! </h3>)}</Tab>

                    <Tab eventKey="Interactive" title="L2" >{this.state.dataOut.length > 0 ? (<L2Bar ticker={this.state.ticker} data={this.state.data} dataOut={this.state.dataOut}/>) :(<h3>No data! </h3>)}</Tab>
                    <Tab eventKey="Sum"  title="By Order">{this.state.aggLoaded? (<L2Table obj_={this.state.chartDataAgg}/>):(<></>)}</Tab>
                    <Tab eventKey="Agg" title="By Price" >{this.state.aggSumLoaded? (<L2AggTable obj_={this.state.chartDataAggSum}/>):(<></>)}</Tab>
                    <Tab eventKey="trades" title="Last Trades" >{this.state.tradeData ? (<TradeTable obj_={this.state.tradeList}/>):(<></>)}</Tab>
                  </Tabs>
                  <Row> <p className="fw-light">Last Updated @ {convertUTCDateToLocalDate(this.state.tradeDateSave)}</p></Row>
                  
                  </Row>
                  
                </Col>
                <Col >
                  <Tabs activeKey={this.state.activeKey} onSelect={(k) => this.setState({ activeKey:k})} fill>
                    <Tab eventKey="management" title="Management" ><CommentCreateManagement slug={this.state.ticker}/></Tab>
                    <Tab eventKey="general"  title="Chat"><CommentCreate slug={this.state.ticker}/></Tab>
                    <Tab eventKey="news" title="News" >{this.state.newsslug_bool ? <NewsPage handler={this.backToAllNews} slug={this.props.params.slug} /> : <CoNewsContainer handler={this.isNewsHandler} ticker={this.state.ticker}/> }</Tab>

                    <Tab eventKey="portfolio" title="Add to Portfolio">                    <TickerDataMiniPortfolio ticker={this.state.ticker}/>
                    </Tab>
                  </Tabs>
                </Col>
                
          

              
          </Row>
       
        <Row>
          
          
          
        </Row>
        <Row>
          

          
        </Row>
          

    </div>
    ):
    (<></>)
  );
};
}


export default (props) => (
    <CompanyPage
        {...props}
        params={useParams()}
    />
);


