import React, { Component }  from "react";

import "./Navbar.css";

import axios from "axios";
import AxiosInstance from "../utils/axiosBase";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaSearch } from "react-icons/fa";

{/* <Form className="d-flex">
    <Form.Control
    type="search"
    placeholder="Search"
    className="me-2"
    aria-label="Search"
    />
    <Button variant="outline-success">Search</Button>
</Form> */}

class Search extends Component {
    token = null;
    state = {
      query: "",
      results: [],
      called:false
    };
  
    updateInputValue(evt) {
        const val = evt.target.value;
        this.setState({
          query: val,
          called:false
        });
      }
  
    search(query){
        if(this.state.called==false & query != ""){
            AxiosInstance.get("app/search-list/" + query)
            .then(response => {
            this.setState({ results: response.data });
            this.state.called=true;
            }).catch(error => {
                //oops                
                this.state.called=true;
            });
        }
    };
  
    componentDidMount() {
      this.search("");
    }
  
    render() {
      return (
        <>
        <InputGroup size="sm" className="sm-0">
        <InputGroup.Text id="basic-addon1"><Button variant="outline-success" onClick={this.search(this.state.query)}><FaSearch/><span className="d-none d-md-block">Search</span></Button></InputGroup.Text>
        <Form.Control
    type="search"
    placeholder="Company"
    className="me-0"
    aria-label="Search"
    value={this.state.query} onChange={evt => this.updateInputValue(evt)}
    />
      </InputGroup>
        {this.state.results?.map(res => (
            <ul key={res.name}>
              <li><a href={"/company/"+res.ticker_no_exchange}>{res.ticker_no_exchange}</a> - {res.name}</li>
            </ul>
          ))}
        </>
      );
    }
  }

  export default Search;