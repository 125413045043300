import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import cssClass from "./AllCommentsList.css";
import Spinner from "../../../components/UI/spinner";

import axios from "axios";
import AxiosInstance from "../../../utils/axiosBase";


class AllCommentsList extends Component {
    constructor() {
        super();
        this.state = {
            allComments: null
        };
    }
    getAllComments = () => {
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
        };
        AxiosInstance.get(
            "/admin-panel/comments/list/all/",
            config
        ).then(response => {
                this.setState({allComments:response.data})
        }).catch(error => {
            alert("Something Went Wrong");
        });
    };
    componentDidMount() {
        this.getAllComments();
    }

    commentDeleteHandler = pk => {
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
        };

        let confirmation = window.confirm(
            "Do You Want To Delete This Comment?"
        );

        if (confirmation) {
            AxiosInstance.delete(
                "/admin-panel/comments/detail/" + pk + "/",
                config
            )
                .then(response => {
                    alert("Comment Deleted");
                    this.getAllComments();
                })
                .catch(error => {
                    alert("Something Went Wrong");
                });
        }
    };

    render() {
        console.log(this.state.allComments);
        let commentsList = this.props.allComments;
        if (this.state.allComments) {
            commentsList = this.state.allComments.map(comment => (
                <tr key={comment.id}>
                    <td>{comment.author_full_name}</td>
                    <td>{comment.body}</td>
                    <td>{new Date(comment.published_on).toDateString()}</td>
                    <td>{comment.flag}</td>
                    {comment.is_displayed ? (
                        <td style={{ color: "green" }}>Active</td>
                    ) : (
                        <td style={{ color: "red" }}>Not Active</td>
                    )}
                    <td>
                        <div className={cssClass.Actions}>
                            <Link
                                to={
                                    "/admin/view/comments-on-post/edit/" +
                                    comment.id +
                                    "/"
                                }
                            >
                                <button>Edit</button>
                            </Link>
                        </div>
                        <button
                            red
                            onClick={() => this.commentDeleteHandler(comment.id)}
                            identifier={comment.id}
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            ));
        }

        let commentsListTable = <Spinner />;

            commentsListTable = (
                <div className={cssClass.OuterWrapper}>
                    <table className={cssClass.Table}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Body</th>
                                <th>Published On</th>
                                <th>Flag Count</th>
                                <th>Status</th>
                                <th>Actions</th>
                                
                            </tr>
                        </thead>
                        <tbody>{commentsList}</tbody>
                    </table>
                </div>
            );

        return (
            <>
                <div className={cssClass.Title}>Comments List</div>
                <div>
                    {this.state.allComments ? commentsListTable : <Spinner />}
                </div>
            </>
        );
    }
}



export default AllCommentsList;
