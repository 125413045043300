import React, { Component } from "react";

import Spinner from "../../components/UI/spinner";
import cssClass from "./CreatePost.css";
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//import Comments from "../../components/Comments/Comments";
//import CommentForm from "../CreateComment/CreateComment";

import axios from "axios";
import AxiosInstance from "../../utils/axiosBase";
import sanitizeHtml from 'sanitize-html';



class PostBody extends Component {
    state = {
        loading: true,
        postBody: null,
        comments: null,
        access : localStorage.getItem("access"),
    };

    getPostBody = () => {
        console.log(this.props);
        const config = {
            headers: {
                "content-type": 'application/json',
                AUTHORIZATION: "JWT " + this.state.access
            }
        };
        AxiosInstance.get("dashboard/view/" + this.props.params.slug+"/", config)
            .then(response =>
                this.setState({ loading: false, postBody: response.data }) 
                
            )
            .catch(err => console.log("Error try again", err));
    };

    // getCommentsList = () => {
    //     AxiosInstance.get("comments/" + this.props.match.params.slug + "/")
    //         .then(response => {
    //             this.setState({ comments: response.data });
    //         })
    //         .catch(error => {
    //             alert("Error Loading Comments. Try Again..!!");
    //         });
    // };

    renderWholePage = () => {
        this.getPostBody();
        //this.getCommentsList();
    };
    componentDidMount() {
        this.renderWholePage();
    };
    sanitizeHtmlfnc(dirty){
        return(sanitizeHtml(dirty, {
            allowedTags: ['b', 'i', 'p', 'strong', 'a' , 'table', 'thead', 'tr', 'th', 'td', 'img', 'src', 'iframe'],
            allowedAttributes: {
              a: ['href'],
            }
          }));
      };

    render() {
        //let postBody = <Spinner />;
        if (this.state.postBody) {
            return(
                <>
                <Container>
                    <Row>
                        <Col>
                        <div className={cssClass.PostBodyDiv}>
                        <h1 className={cssClass.Title}>
                            {this.state.postBody.title}
                        </h1>
                        <p className={cssClass.PublishedDate}>
                            <>Written by: </>{this.state.postBody.author_full_name}
                            <> On: </><>
                            {new Date(
                                this.state.postBody.published_on
                            ).toDateString()}</>
                        </p>
                        <div dangerouslySetInnerHTML={{ __html:this.sanitizeHtmlfnc(this.state.postBody.body)}}/>
                        
                        <h1 className={cssClass.CommentHeading}>
                            Comments: {this.state.postBody.total_comments}
                        </h1>
                    </div>
                        </Col>
                    </Row>
                </Container>
                  
                    {/* <Comments commentsList={this.state.comments} />
                    <CommentForm
                        slug={this.props.match.params.slug}
                        refresh={this.renderWholePage}
                    /> */}
                    </>
                
            );
        }

    }
}

export default (props) => (
    <PostBody
        {...props}
        params={useParams()}
    />
);

