import React, { Component,useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosResponse } from 'axios';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

import CommentCreate from '../components/CommentCreate'

import AxiosInstance from "../utils/axiosBase";

function convertUTCDateToLocalDate(date) {
  var d = new Date(date);
  var mins_ = d.getMinutes();
  if (mins_ == 0){
      mins_ = '00'
  }
  if(mins_ < 10 & mins_ > 0){
      mins_ = '0'+String(mins_)
  }
  var datestring = (d.getMonth()+1) +"-"+ d.getDate()  + "-" + d.getFullYear() + " " +d.getHours() + ":" + mins_;

  return datestring;   
};


class News extends Component {
    constructor(props) {
      
      super(props);
      this.state = {

        news: [],
        title_slug: this.props.slug,
        comments: null
        };
    };
    


      async componentDidMount() {
        try {
          const session_url = `/app/news/${this.props.params.slug}`;
         

          var config = {
            method: 'get',
            url: session_url
          };

          var response = await axios(config);
          const news = response.data;
          this.setState({
            news
          });


        } catch (e) {
          console.log(e);
        }
      };
      sanitizeHtml = (dirty) => {
        return(sanitizeHtml(dirty, {
            allowedTags: ['b', 'i', 'p', 'strong', 'a' , 'table', 'thead', 'tr', 'th', 'td', 'img', 'src'],
            allowedAttributes: {
              a: ['href']
            }
          }));
      };


render(){
 return (
  <Container>
          <Row>
            <Col>
            <div>
              <Link onClick={this.props.handler} to={`/company/${this.state.news.ticker_no_exchange}`}><u><i>... Back to All News</i></u></Link></div>
            <div className="container">
              <h1>{this.state.news.title}</h1>
              <h2><i>Published: {convertUTCDateToLocalDate(this.state.news.pubdate)}</i></h2>
              <br></br>
              {/* Summary: {this.state.news.summary?.substring(0,500)}...
              <br></br>  */}
              <div dangerouslySetInnerHTML={{__html: this.sanitizeHtml(this.state.news.fulltext)}} />
              <br></br>
            </div>
            </Col>
            <Col md="auto">
              <CommentCreate slug={this.state.title_slug}/>
            </Col>
          </Row>
  </Container>
 );
}
}

export default (props) => (
    <News
        {...props}
        params={useParams()}
    />
);
