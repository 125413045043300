import React, { Component, useContext, useState } from "react";
import { connect } from "react-redux";

import cssClass from "../../blog/CreatePost.css";
import InputObj from "../../inputs/inputs.js";
import Spinner from "../../../components/UI/spinner.js";
import { checkValidity } from "../../formCheck/checkValidity.js";
import "../../blog/blog.css";
import * as actions from "../../Actions/actions.js";

import { useEditor, EditorContent, FloatingMenu, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Youtube from '@tiptap/extension-youtube';


import { FaTrash, FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AxiosInstance from "../../../utils/axiosBase.js";

var UserContext = React.createContext(null);

const MenuBar = ({ editor }) => {
    const widthRef = React.useRef(null)
    const heightRef = React.useRef(null)
  
    React.useEffect(() => {
      if (widthRef.current && heightRef.current) {
        widthRef.current.value = 640
        heightRef.current.value = 480
      }
    }, [widthRef.current, heightRef.current])
  
    if (!editor) {
      return null
    }
  
    const addYoutubeVideo = () => {
      const url = prompt('Enter YouTube URL')
  
      if (url) {
        editor.commands.setYoutubeVideo({
          src: url,
          width: Math.max(320, parseInt(widthRef.current.value, 10)) || 640,
          height: Math.max(180, parseInt(heightRef.current.value, 10)) || 480,
        })
      }
    }
  
    return (
      <>
        <button id="add" onClick={addYoutubeVideo}>Add YouTube video</button>
        <input id="width" type="number" min="320" max="1024" ref={widthRef} placeholder="width" />
        <input id="height" type="number" min="180" max="720" ref={heightRef} placeholder="height" />
      </>
    )
  }

function Editor(props) {
    
    const editor = useEditor({
        extensions: [
            StarterKit, 
            Youtube.configure({
                controls: false,
              }),
            ],
        onUpdate({ editor }) {
          UserContext = String(editor.getHTML());
        },
        content: "<p>Start your post here . . . </p>"
      });
    
    return props.valid? (<><div><br /><EditorContent key={props.id} editor={editor} {...props.elementConfig}
        value={props.value}
        onChange={props.onUpdate}/></div><p>Your post is valid!</p><FaCheckCircle/></>):(
            <><div><br /><EditorContent key={props.id} editor={editor} {...props.elementConfig}
        value={props.value}
        onChange={props.onUpdate}/></div><p><FaExclamationTriangle/>To validate your post, ensure proper length and tags are added.</p></>
        )
    ;
  }



class HashPageCreate extends Component {
    constructor() {
        super();
        this.state = {
          

          postCreationForm: {
            title: {
                elementType: "input",
                elementConfig: {
                    type: "input",
                    placeholder: "Hashtag of Page (no hash needed)"
                },
                value: "",
                validation: {
                    required: true,
                    minLength: 2
                },
                valid: false,
                touched: false
            },
            body: {
                elementType: "TextField",
                elementConfig: {
                    type: "TextField",
                    placeholder: "Body Of The Post"
                },
                value: "",
                validation: {
                    required: true,
                    minLength: 10,
                    maxLength: 6000
                },
                valid: false,
                touched: false,
                writable: true
            },
        },
        ispostCreationFormValid: false
          };
      };


      inputChangedHandler = (event, inputIndentifier) => {

        const updatedPostCreationForm = {
            ...this.state.postCreationForm
        };
        const updatedFormElement = {
            ...updatedPostCreationForm[inputIndentifier]
        };
        
        if(true){
            // body check
            const updatedPostCreationFormBody = {
                ...this.state.postCreationForm
            };
            const updatedFormElementBody = {
                ...updatedPostCreationFormBody.body
            };
            updatedFormElementBody.value = UserContext

            updatedFormElementBody.valid = true;
            updatedFormElementBody.touched = true;
                    
            updatedPostCreationForm["body"] = updatedFormElementBody;
            
            
            
        }
        
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValidity(
            updatedFormElement.value,
            updatedFormElement.validation
        );
        updatedFormElement.touched = true;
        updatedPostCreationForm[inputIndentifier] = updatedFormElement;
        let isFormValid = true;
        for (let inputIndentifier in updatedPostCreationForm) {
            isFormValid =
                updatedPostCreationForm[inputIndentifier].valid && isFormValid;
        }
        this.setState({
            postCreationForm: updatedPostCreationForm,
            ispostCreationFormValid: isFormValid
        });
    };

    onFormSubmitEventHandler = event => {
        event.preventDefault();
        if(this.state.ispostCreationFormValid)  {
            const postData = {
                title: this.state.postCreationForm.title.value,
                body: this.state.postCreationForm.body.value
            };
            console.log(postData);
            const access = localStorage.getItem("access");
            console.log(access);
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                    AUTHORIZATION: "JWT " + access,
                }
            };
            console.log(config);
            //this.props.onCreateNewPost(postData, config);
            AxiosInstance.post(
                "/admin-panel/create-hash-content/", 
                postData, config
            ).then(response => {
                alert("Post Updated Successfully!");
                window.location.reload();
            }).catch(error => {
                alert(error);
            });
        }
    };

    render() {
        let formElements = [];
        for (let key in this.state.postCreationForm) {
            formElements.push({
                id: key,
                config: this.state.postCreationForm[key]
            });
        }

        let form = <Spinner />;
        if (this.state.postCreationForm) {
            
            form = (<>
                    
                    <Container>
                    <Row>
                        <Col>
                        <h1>
                        Create A New HashPage
                    </h1>
                    <form onSubmit={this.onFormSubmitEventHandler}>
                        {formElements.map(formElement => {
                            if(formElement.config.elementType=='TextField')
                                return <Editor valid={formElement.config.valid} onUpdate={event => this.inputChangedHandler("Body", "TextField")}/>
                            
                            return <InputObj
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                changed={event =>
                                    this.inputChangedHandler(
                                        event,
                                        formElement.id
                                    )
                                }
                                valid={formElement.config.valid}
                                id_element = {formElement.config.elementType}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}                                
                            />
                        })}
                        <br></br>
                        <button disabled={!this.state.ispostCreationFormValid }>
                            Submit
                        </button>    
                    </form>
                    
                    </Col></Row></Container>
                    
                    </>
            );
        }

        return(<div className={cssClass.OuterWrapper}>{form}</div>);
    }
}


export default HashPageCreate;