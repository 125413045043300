import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Provider } from "react-redux";

import configureStore from "./store/configureStore";

import Layout from "./hoc/Layout";

import HomePage from "./pages/HomePage";
import UserProfileEdit from "./pages/UserPageEdit";
import UserProfileView from "./pages/UserProfileView";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import News from "./pages/NewsPage";
import HashtagPage from "./pages/HashtagPage";
import Company from "./pages/CompanyPage";
import ActivateAccountPage from "./pages/ActivateAccountPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ResetPasswordConfirmPage from "./pages/ResetPasswordConfirmPage";
import Google from "./pages/Google";
import Posts from "./pages/Posts";

import CreatePost from "./pages/blog/CreatePost";
import PostList from "./pages/blog/PostList";
import PostEdit from "./pages/blog/PostEdit";
import PostBody from "./pages/blog/PostView";
import PostBodyNA from "./pages/blog/PostViewNonAdmin";

import HashPageCreate from "./pages/AdminPanel/HashtagPage/Hash";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import UserList from "./pages/AdminPanel/UserList/UserList";
import EditUser from "./pages/AdminPanel/EditUser/EditUser";
import AdPostList from "./pages/AdminPanel/PostList/PostList";
import AdPostCommentsList from "./pages/AdminPanel/PostCommentsList/PostCommentsList";
import AdEditComment from "./pages/AdminPanel/CommentEdit/CommentEdit";
import AllCommentsList from "./pages/AdminPanel/AllCommentsList/AllCommentsList";
import AdPostEdit from "./pages/AdminPanel/EditPost/EditPost";
import ManEdit from "./pages/AdminPanel/ManagementEdit/ManagementEdit";
import CommentModal from "./components/CommentModal";
import About from "./pages/about";
import ReactGA from 'react-ga4';
import { useEffect } from "react";
import ComTab from "./components/CommodityTable";
import TrendPage from "./pages/trendingPage";

import AllChats from "./pages/AllComments";


const store = configureStore();
const TRACKING_ID = "G-879EKCR2M9"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);






export default function App(){
  const location = useLocation();
  const background = location.state && location.state.background;
  //{background &&
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);




  return (
    <Provider store={store}>
        <Layout>
          <Routes location={background || location}>
            <Route path="/" element={<HomePage />} />
            <Route path="/google" element={<Google />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/posts" element={<Posts />} />
            <Route path="/chats" element={<AllChats />} />


            <Route path="/news/:slug" element={< News />} />
            <Route path="/commodity-table" element={< ComTab />} />
            <Route path="/trending" element={< TrendPage />} />
            <Route path="/page/:slug" element={< HashtagPage />} />
            <Route path="/company/:ticker" element={< Company />} />
            <Route path="/company/:ticker/:slug" element={< Company />} />
            <Route path="/comment/share/:ticker/:slug" element={< CommentModal />} />



            <Route
              path="/activate/:uid/:token"
              element={<ActivateAccountPage />}
            />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/password/reset/confirm/:uid/:token"
              element={<ResetPasswordConfirmPage />}
            />

          <Route path="/create-post" element={<CreatePost />} />
          <Route path="/list-post" element={<PostList/>}/>
          <Route path="/profile/:slug/" element={<UserProfileView/>}/>
          <Route path="/profile/:slug/edit/" element={<UserProfileEdit/>}/>
          <Route path="/post/edit/:slug/" element={<PostEdit/>}/>
          <Route path="/admin/post/view/:slug/" element={<PostBody/>} />
          <Route path="/post/view/:slug/" element={<PostBodyNA/>} />


          <Route path="/admin/view/" element={<AdminPanel/>} />
          <Route path="/admin/view/post-edit/:slug/" element={<AdPostEdit/>} />
          <Route path="/admin/view/hashpage-edit/" element={<HashPageCreate/>} />
          <Route path="/admin/view/user-list/" element={<UserList/>} />
          <Route path="/admin/view/user-edit/:pk/" element={<EditUser/>} />
          <Route path="/admin/view/user-management/:pk/" element={<ManEdit/>} />
          <Route path="/admin/view/post-list/" element={<AdPostList/>} />
          <Route path="/admin/view/comment-list/" element={<AllCommentsList/>} />

          <Route path="/admin/view/comments-on-post/:slug/" element={<AdPostCommentsList/>} />
          <Route path="/admin/view/comments-on-post/:slug/" element={<AdPostCommentsList/>} />
          <Route path="/admin/view/comments-on-post/edit/:pk/" element={<AdEditComment/>} />
          </Routes>

        </Layout>
    </Provider>
  );
};

