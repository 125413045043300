import React, { useState } from "react";
import cssClass from "./inputs.css";
import Editor from  "./tinyMce.js";
import { FaTrash, FaCheckCircle, FaExclamationCircle, FaCheck } from 'react-icons/fa';

import Select from 'react-select';

const InputObj = props => {
    const inputClasses = [];
    const [selectedImage, setSelectedImage] = useState(null);

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(cssClass.Invalid);
    }
    switch (props.elementType) {
        case "input":
            
            //console.log(props.elementType);
            inputClasses.push(cssClass.TextField);
            return props.valid? (
                <><br /><input
                    className={inputClasses.join(" ")}
                    {...props.elementConfig}
                    value={props.value}
                    onChange={props.changed}
                /><FaCheckCircle/></>
            ):(<><br /><input
                className={inputClasses.join(" ")}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
            /><FaExclamationCircle/></>);

        case "dropdown":
                //console.log(props.options);
                return props.options ?(
                    <Select multi={false}
                    name = {props.name}
                    options={props.options.data.map((o, i) => {
                      return { id: i, value: o.value, label: o.label };
                    })}
                    onChange={props.changed}

                    />
                ):(<></>);
            
        case "textarea":
            inputClasses.push(cssClass.TextArea);
            return props.valid ?(<>
                <textarea
                    className={inputClasses.join(" ")}
                    {...props.elementConfig}
                    value={props.value}
                    onChange={props.changed}
                /></>
            ):(<><textarea
                className={inputClasses.join(" ")}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
            /></>);

        case "TextField":
            //console.log(props.elementType);
            inputClasses.push(cssClass.TextArea);
            //console.log(props);
            
            return (
                Editor(props)
            );

        case "checkbox":
            inputClasses.push(cssClass.Checkbox);
            return (
                <div>
                    <input
                        className={inputClasses.join(" ")}
                        {...props.elementConfig}
                        value={props.value}
                        onChange={props.changed}
                    />{" "}
                    <span className={cssClass.CheckboxText}>
                        {props.elementConfig.label}
                    </span>
                    <br />
                </div>
            );
        case "img":
            return props.valid ? (
            <><div><br></br>
                
                {selectedImage && (
                  <div>
                    <img
                      alt="not found"
                      width={"100px"}
                      src={URL.createObjectURL(selectedImage)}
                    />
                    <br />
                    <button onClick={() => setSelectedImage(null)}><FaTrash /></button>
                  </div>
                )}
          
                <br />
                <br />
                
                <input
                  type="file"
                  name="myImage"
                  {...props.elementConfig}
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    setSelectedImage(event.target.files[0]);
                    
                  }
                }
                />
              </div><FaCheckCircle/></>):(<><div><br></br>
                
                {selectedImage && (
                  <div>
                    <img
                      alt="not found"
                      width={"100px"}
                      src={URL.createObjectURL(selectedImage)}
                    />
                    <br />
                    <button onClick={() => setSelectedImage(null)}><FaTrash /></button>
                  </div>
                )}
          
                <br />
                <br />
                
                <input
                  type="file"
                  name="myImage"
                  {...props.elementConfig}
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    setSelectedImage(event.target.files[0]);
                    
                  }
                }
                />
              </div><FaExclamationCircle/></>);
        // default:
        //     inputClasses.push(cssClass.TextField);
        //     return (
        //         <input
        //             className={inputClasses.join(" ")}
        //             {...props.elementConfig}
        //             value={props.value}
        //             onChange={props.changed}
        //         />
        //     );
    }
};

export default InputObj;
