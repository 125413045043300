import { useEditor, EditorContent, FloatingMenu, BubbleMenu } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, { setState, useState } from "react";
import { FaCheckCircle, FaExclamationTriangle, FaCheck } from 'react-icons/fa';


//getting rid of using this file for favour of trying to keep state in the createpost class
// accept function here for changing state

export const UserContext = React.createContext(null);

function Editor(props) {
    console.log("log on every editor change");
    console.log(props)
    
    const editor = useEditor({
        extensions: [StarterKit],
        onUpdate({ editor }) {
          console.log(String(editor.getHTML()));
          <UserContext.Provider value={{ valule: editor.getHTML()}}></UserContext.Provider>
          //props.value = JSON.stringify(editor.getHTML());
        },
        content: "<p>Hello World!</p>"
      });
  
    return props.valid? (<><EditorContent key={props.id} editor={editor} {...props.elementConfig}
    value={props.value}
    onChange={props.changed}/><FaCheckCircle/></>):(<><EditorContent key={props.id} editor={editor} {...props.elementConfig}
      value={props.value}
      onChange={props.changed}/><FaExclamationTriangle/></>);
  }

export default Editor; 