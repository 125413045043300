import React, { Component } from "react";
import { connect } from "react-redux";

import cssClass from "./AdminPanel/EditUser/EditUser.css";
import Spinner from "../components/UI/spinner";
import Input from "./inputs/inputs";

import axios from "axios";
import AxiosInstance from "../utils/axiosBase";

import { checkValidity } from "./formCheck/checkValidity";
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class UserProfileEdit extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            editUserForm: null,
            isUserEditFormValid: false,
            slug: this.props.params.slug
        };
    }

    componentDidMount() {
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
        };
        
        AxiosInstance.get("/admin-panel/users/detail/"+this.state.slug+"/eus/", config)
            .then(response => {
                
                if(response.data.detail = 'Ready to edit'){
                this.setState({
                    
                    editUserForm: {
                        first_name: {
                            elementType: "input",
                            elementConfig: {
                                type: "text",
                                placeholder: "first_name",
                                readOnly: true
                            },
                            value: response.data.first_name,
                            valid: true,
                            touched: true
                        },
                        last_name: {
                            elementType: "input",
                            elementConfig: {
                                type: "text",
                                placeholder: "last_name",
                                readOnly: true
                            },
                            value: response.data.last_name,
                            valid: true,
                            touched: true
                        },
                        claimed_handle: {
                            elementType: "input",
                            elementConfig: {
                                type: "text",
                                placeholder: "claimed_handle"
                            },
                            validation: {
                                required: true,
                                maxLength: 30,
                                minLength: 3
                            },
                            valid: true,
                            value: response.data.claimed_handle,
                            touched: true
                        },
                        email: {
                            elementType: "input",
                            elementConfig: {
                                type: "email",
                                placeholder: "Email"
                            },
                            value: response.data.email,
                            validation: {
                                required: true,
                                isEmail: true
                            },
                            valid: true,
                            touched: true
                        },
                        
                        website: {
                            elementType: "input",
                            elementConfig: {
                                type: "url",
                                placeholder: "Your Website (If Any)"
                            },
                            value: response.data.website,
                            valid: true,
                            touched: true
                        },
                        bio: {
                            elementType: "textarea",
                            elementConfig: {
                                type: "textarea",
                                placeholder: "Describe Yourself In Few Words"
                            },
                            value: response.data.bio,
                            validation: {
                                required: true,
                                maxLength: 5000,
                                minLength: 10
                            },
                            valid: true,
                            touched: true
                        },
                        country: {
                            elementType: "input",
                            elementConfig: {
                                type: "text",
                                placeholder: "Country"
                            },
                            value: response.data.country,
                            valid: true,
                            touched: true
                        },
                        twitter_handler: {
                            elementType: "input",
                            elementConfig: {
                                type: "text",
                                placeholder: "Twitter Username (With @)"
                            },
                            value: response.data.twitter_handler,
                            valid: true,
                            touched: true
                        }
                    }
                });
            }
            })
            .catch(error => {
                alert("Something Went Wrong");
            });
    }

    inputChangedHandler = (event, inputIndentifier) => {
        const updatedEditUserForm = {
            ...this.state.editUserForm
        };
        const updatedFormElement = {
            ...updatedEditUserForm[inputIndentifier]
        };
        if (
            inputIndentifier === "is_active" ||
            inputIndentifier === "is_staff" ||
            inputIndentifier === "is_superuser"
        ) {
            updatedFormElement.value = !this.state.editUserForm[
                inputIndentifier
            ].value;
            updatedFormElement.elementConfig.checked = updatedFormElement.value;
            updatedFormElement.touched = true;
        } else {
            updatedFormElement.value = event.target.value;
            updatedFormElement.valid = checkValidity(
                updatedFormElement.value,
                updatedFormElement.validation
            );
            updatedFormElement.touched = true;
        }
        updatedEditUserForm[inputIndentifier] = updatedFormElement;
        let isFormValid = true;
        for (let inputIndentifier in this.state.editUserForm) {
            
            isFormValid =
                updatedEditUserForm[inputIndentifier].valid && isFormValid;
        }
        this.setState({
            editUserForm: updatedEditUserForm,
            isUserEditFormValid: isFormValid
        });
    };

    onFormSubmitEventHandler = event => {
        event.preventDefault();
        let updatedForm = {};
        for (let key in this.state.editUserForm) {
            updatedForm[key] = this.state.editUserForm[key].value;
        };
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
        };
        const data = {
                slug: this.state.slug,
                ...updatedForm
            };
        AxiosInstance.put("/admin-panel/users/detail/"+this.state.slug+"/eus/", data, config)
            .then(response => {
                
                  alert("Completed Save");
                  window.location.reload();  
                })
            .catch(response => {alert(response)});
    };

    render() {
        let formElements = [];
        for (let key in this.state.editUserForm) {
            formElements.push({
                id: key,
                config: this.state.editUserForm[key]
            });
        }

        let form = <Spinner />;
        if (this.state.editUserForm) {
            form = (
                <>
                    <h1
                        style={{
                            fontFamily: "Roboto, sans-serif",
                            fontWeight: "200"
                        }}
                    >
                        Edit User
                    </h1>
                    <form onSubmit={this.onFormSubmitEventHandler}>
                        {formElements.map(formElement => (
                            <Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                changed={event =>
                                    this.inputChangedHandler(
                                        event,
                                        formElement.id
                                    )
                                }
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                            />
                        ))}
                        <button disabled={!this.state.isUserEditFormValid}>
                            Submit
                        </button>
                    </form>
                </>
            );
        }
        return this.props.loading ? (
            <Spinner />
        ) : (
            <Container>
                <Col>
                <div className={cssClass.OuterWrapper}>
                    {form}
                </div>
                </Col>
            </Container>
            
        );
    }
}



export default (props) => (
    <UserProfileEdit
        {...props}
        params={useParams()}
    />
);