import { useState, useRef } from "react";
import React from "react";
import  {Component} from "react";


import axios from "axios";

import { Link } from "react-router-dom";

import { FaChartLine, FaRegNewspaper } from 'react-icons/fa';


import Filter from "./Filter";


import Col from 'react-bootstrap/Col';



const todoList = [];
var maxofAll = 0;
var minofAll = 0;

function ValPicker(low, high){
    const [valFilter, setValFilter] = useState({
        startVal: low, 
        endVal:high
    });


    return valFilter
}

function findMinMax(array_, key) {
    const datas = array_.map((node) => node[key]);
    console.log(datas);
    return {
      min: Math.min(...datas),
      max: Math.max(...datas),
    }
  }
  
  function currencyFormat(num) {
    var num = parseFloat(num);
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }



// function Avatar({ ticker_new }) {
//   const [showPopup, setShowPopup] = useState({ open: false, popupMessage: "" });
//   const [style, setStyle] = useState({ top: 0, left: 0 });
//   const btnOneRef = useRef();

//   const toggleModal = (Msg) => {
//     // console.log("test");
//     // console.log(Msg);
//     //  setShowPopup(!showPopup.open);
//     setShowPopup({ open: !showPopup.open, popupMessage: Msg });
//     let left = btnOneRef.current.getBoundingClientRect().left;
//     let top = btnOneRef.current.getBoundingClientRect().top;
//     setStyle({ top, left });
//   };

//   return (
//     <div className="chart">
//         <button
//           ref={btnOneRef}
//           onClick={() => {
//             toggleModal(ticker_new);
//           }}
//         >
//           <FaChartLine />
//         </button>
//     <div className="pup">
//     {showPopup.open && <Popup
//       data={ticker_new} 
//       handleClose={toggleModal}
//     />}

 
//       </div>
      
      
//       </div>
      
//     )
// }


class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      todoList: []
      };
  }
   
    async componentDidMount() {
      try {
        const session_url = '/app/homepage-selection/';

        const access = localStorage.getItem("access");


        var config = {
          method: 'get',
          url: session_url
        };

        var response = await axios(config);
        const todoList = response.data;
        this.setState({
          todoList
        });
        
        
      } catch (e) {
        console.log(e);
    }
    }
    renderCheckPattern(item){
      //console.log(item.wedge_pattern);
      
      if(item.double_pattern)
          return(<td>{item.double_pattern}</td>)
      if(item.triangle_pattern)
          return(<td>{item.triangle_pattern}</td>)
      if(item.head_shoulder_pattern)
          return(<td>{item.head_shoulder_pattern}</td>)
      if(item.multiple_top_bottom_pattern)
          return(<td>{item.multiple_top_bottom_pattern}</td>)
      if(item.channel_pattern)
        return(<td>{item.channel_pattern}</td>)
      if(item.wedge_pattern)
          return(<td>{item.wedge_pattern}</td>)
      else
        return (<td></td>)
      }
      renderCheckNews(item){   
        //console.log(item.news_on_day);     
        if(item.news_on_day == true)
            return(<td><Link to={`/news/${item.title_slug}`}><button><FaRegNewspaper /></button></Link></td>)
        
        if(item.news_on_day == false)
          return (<td></td>)
        }
    renderItems = () => {
      const { todoList = [] } = this.state;
      //console.log(todoList)
      return todoList.map((item, index) => (              
                <tr key={index}>
                  <td><a href={"/company/"+item.ticker_no_exchange}>{item.ticker}</a></td>
                  <td>{item.sector}</td>
                  <td className='isDollar'>{currencyFormat(item.new_mcap)}</td>
                  {this.renderCheckPattern(item)}
                  {this.renderCheckNews(item)}
                  <td>{item.date}</td>
                  
                </tr>
            )
          );
      };

      handleCallback = (childData) => {
        // Update the name in the component's state
        this.setState({ todoList: childData });
    };

    render() {
      return (
        <Col>
        
        
        <Filter parentCallback={this.handleCallback}/>
              <table>
                  <thead>
                      <tr>
                          <th>Company</th>
                          <th>Sector</th>
                          <th>Market Cap</th>
                          <th>Pattern</th>
                          <th>News</th>
                          <th>Pattern Date</th>
                      </tr>
                  </thead>
                  <tbody>
                  {this.renderItems()}
                  </tbody>
              </table>
              </Col>
          
      )
    }
  }

export default Table;