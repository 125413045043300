import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/esm/Row";
import Table from "react-bootstrap/esm/Table";
import cssClass from "./AdminPanel.css";

class AdminPanel extends Component {
    render() {
        return (
            <>
            <div className="container">
                <Row> 
                <h2> Admin Panel </h2>
                <Table>
                    <tr>
                        <td>
                        <Button>
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/admin/view/user-list/"
                        >
                            <div className={cssClass.Container}>
                                View All Users
                            </div>
                        </Link>
                        </Button>
                        </td>
                        <td>
                        <Button>
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/admin-panel/create-user"
                        >
                            <div className={cssClass.Container}>
                                Create A New User
                            </div>
                        </Link>
                        </Button>
                        </td>
                    </tr>
                    <tr><td>
                    <Button>
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/admin/view/post-list/"
                        >
                            <div className={cssClass.Container}>
                                View All Posts
                            </div>
                        </Link>
                        </Button>
                        </td>
                        <td>
                        <Button>
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/dashboard/create-new-post"
                        >
                            <div className={cssClass.Container}>
                                Create A New Post
                            </div>
                        </Link>
                        </Button>
                        </td>
                    </tr>
                    <tr>
                    <td>
                    
                        <Button>
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/admin/view/comment-list/"
                        >
                            <div className={cssClass.Container}>
                                View All Comments
                            </div>
                        </Link>
                        </Button>
                        </td>
                        <td><Button>
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/admin/view/hashpage-edit/"
                        >
                            <div className={cssClass.Container}>
                                Create Hashpage Content
                            </div>
                        </Link>
                        </Button></td>
                    </tr>
                    <tr><td><Button>
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to=""
                        >
                            <div className={cssClass.Container}>
                                Edit Hashpage
                            </div>
                        </Link>
                        </Button></td></tr>
                    </Table>
                </Row>
                </div>
            </>
        );
    }
}

export default AdminPanel;
