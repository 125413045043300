// Post List For Dashboard

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Input from "../../inputs/inputs";

//import cssClass from "./PostList.css";
import cssClass from "../../blog/CreatePost.css";
import Spinner from "../../../components/UI/spinner";
import { connect } from "react-redux";
import { checkValidity } from "../../formCheck/checkValidity";
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useEditor, EditorContent, FloatingMenu, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import axios from "axios";
import AxiosInstance from "../../../utils/axiosBase";
import Youtube from '@tiptap/extension-youtube';


var UserContext = React.createContext(null);


const MenuBar = ({ editor }) => {
    const widthRef = React.useRef(null)
    const heightRef = React.useRef(null)
  
    React.useEffect(() => {
      if (widthRef.current && heightRef.current) {
        widthRef.current.value = 640
        heightRef.current.value = 480
      }
    }, [widthRef.current, heightRef.current])
  
    if (!editor) {
      return null
    }
  
    const addYoutubeVideo = () => {
      const url = prompt('Enter YouTube URL')
  
      if (url) {
        editor.commands.setYoutubeVideo({
          src: url,
          width: Math.max(320, parseInt(widthRef.current.value, 10)) || 640,
          height: Math.max(180, parseInt(heightRef.current.value, 10)) || 480,
        })
      }
    }
  
    return (
      <>
        <button id="add" onClick={addYoutubeVideo}>Add YouTube video</button>
        <input id="width" type="number" min="320" max="1024" ref={widthRef} placeholder="width" />
        <input id="height" type="number" min="180" max="720" ref={heightRef} placeholder="height" />
      </>
    )
  }

function Editor({props}) {
    
    const editor = useEditor({
        extensions: [
            StarterKit, 
            Youtube.configure({
                controls: false,
              }),
            ],
        onUpdate({ editor }) {
          UserContext = String(editor.getHTML());        
        },
        content: props.config.value
      });
  
    return(<div><MenuBar editor={editor}/><EditorContent key={props.id} editor={editor} {...props.elementConfig}
        value={props.value}
        onChange={props.changed}/></div>)
    ;
  }




function userPostEdit(updatedPost, slug){
    const access = localStorage.getItem("access");
    const config = {
        headers: {
            "content-type": 'application/json',
            AUTHORIZATION: "JWT " + access
        }
    };
    AxiosInstance.post("/admin-panel/posts/view/"+slug+"/", updatedPost, config)
        .then(response => {
            alert("Post Updated Successfully");
            
        })
        .catch(error => {
            //alert("Something Went Wrong... Try Again");
            
        });
    
};

class AdPostEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postEditForm: null,
            isPostEditFormValid: true,
            userPost: null,
            slug: this.props.params.slug,
            access : localStorage.getItem("access"),
        };
    }

    

    componentWillMount() {
        const config = {
            headers: {
                "content-type": 'application/json',
                AUTHORIZATION: "JWT " + this.state.access
            }
        };
        AxiosInstance.get(
            `/admin-panel/posts/view/${this.state.slug}/`, config
        ).then(response => {
            this.setState({
                postEditForm: {
                    title: {
                        elementType: "input",
                        elementConfig: {
                            type: "input",
                            placeholder: "Title Of The Post"
                        },
                        value: response.data.title,
                        validation: {
                            required: true,
                            minLength: 0
                        },
                        valid: true,
                        touched: true
                    },
                    body: {
                        elementType: "TextField",
                        elementConfig: {
                            type: "TextField",
                            placeholder: "Body Of The Post"
                        },
                        value: response.data.body,
                        validation: {
                            required: true,
                            minLength: 0
                        },
                        valid: true,
                        touched: true
                    },
                    short_description: {
                        elementType: "input",
                        elementConfig: {
                            type: "input",
                            placeholder: "Short Description Of The Post"
                        },
                        value: response.data.short_description,
                        validation: {
                            required: true,
                            minLength: 0
                        },
                        valid: true,
                        touched: true
                    },
                    is_published: {
                        elementType: "checkbox",
                        elementConfig: {
                            type: "checkbox",
                            label: "Published",
                            name: "published",
                            checked: response.data.is_published
                                ? true
                                : false
                        },
                        value: response.data.is_published ? true : false,
                        valid: true,
                        touched: true
                    }
                }
            });
        });
    }

    inputChangedHandler = (event, inputIndentifier) => {

        const updatedPostEditForm = {
            ...this.state.postEditForm
        };
        const updatedFormElement = {
            ...updatedPostEditForm[inputIndentifier]
        };
        if(true){
            // body check
            const updatedPostEditFormBody = {
                ...this.state.postEditForm
            };
            const updatedFormElementBody = {
                ...updatedPostEditFormBody.body
            };
            updatedFormElementBody.value = UserContext

            updatedFormElementBody.valid = true;
            updatedFormElementBody.touched = true;
            updatedPostEditForm["body"] = updatedFormElementBody;

            if (inputIndentifier === "is_published") {
                updatedFormElement.value = !this.state.postEditForm.is_published
                    .value;
                updatedFormElement.elementConfig.checked = updatedFormElement.value;
                updatedFormElement.touched = true;
            }
            
        }
        
        updatedPostEditForm[inputIndentifier] = updatedFormElement;
        let isFormValid = true;
        for (let inputIndentifier in updatedPostEditForm) {
            isFormValid =
                updatedPostEditForm[inputIndentifier].valid && isFormValid;
        }
        this.setState({
            postEditForm: updatedPostEditForm,
            ispostEditFormValid: isFormValid
        });
    };

    onFormSubmitEventHandler = event => {
        console.log('hit');
        event.preventDefault();
        let updatedForm = {};
        for (let key in this.state.postEditForm) {
            updatedForm[key] = this.state.postEditForm[key].value;
        }
        updatedForm["slug"] = this.props.params.slug;
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
        };
        console.log('hit2');
        AxiosInstance.patch(
                "/admin-panel/posts/view/"+this.props.params.slug+"/", 
                updatedForm, config
            ).then(response => {
                alert("Post Updated Successfully!");
                window.location = '/list-post/'
            }).catch(error => {
                alert(error);
            });
    };

    render() {
        let formElements = [];
        for (let key in this.state.postEditForm) {
            formElements.push({
                id: key,
                config: this.state.postEditForm[key]
            });
        }

        let form = <Spinner />;
        if (this.state.postEditForm) {
            form = (
                <>
                <Container>
                    <Row>
                        <Col>
                    <h1
                        style={{
                            fontFamily: "Roboto, sans-serif",
                            fontWeight: "200"
                        }}
                    >
                        Edit Post
                    </h1>
                    <form onSubmit={this.onFormSubmitEventHandler}>
                        {formElements.map(formElement => {
                            if(formElement.config.elementType=='TextField')
                                
                                return <Editor props={formElement} onUpdate={event => this.inputChangedHandler("Body", "TextField")}/>
                            else
                                return <Input
                                    key={formElement.id}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    changed={event =>
                                        this.inputChangedHandler(
                                            event,
                                            formElement.id
                                        )
                                    }
                                    id_element = {formElement.config.elementType}
                                    invalid={!formElement.config.valid}
                                    shouldValidate={formElement.config.validation}
                                    touched={formElement.config.touched}                                
                                    />
                        
                                })}
                        <button disabled={!this.state.isPostEditFormValid} 
                        >Submit
                        </button>
                    </form>
                    </Col>
                    </Row>
                </Container>
                </>
            );
        }
        //return this.props.loading ? 
        //    <Spinner />
        //) : (
        return(<div className={cssClass.OuterWrapper}>{form}</div>);
        
    }
}




export default (props) => (
    <AdPostEdit
        {...props}
        params={useParams()}
    />
);

