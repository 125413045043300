import React, { Component} from "react";


import Row from 'react-bootstrap/Row';

import { useParams, useLocation } from 'react-router-dom';

import "../components/threads.css";


import CommentAll from "../components/CommentAll";

import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';



class AlLChats extends Component {
    constructor(props) {
      super(props);
      this.state = {

        
        page_data:null,
        comments:[]
        };
    };


    

render(){
 return (
  
    <div className="container">
          <Row> 
          

              <Col sm={4}><h2>All Chats</h2>
              
            
              <br />
                
                
                
                
                
              </Col>
              <Col sm={8}>
            <Tabs defaultActiveKey="general" className="mb-3">
              <Tab eventKey="general" title={
                <React.Fragment>
                Main
                
                </React.Fragment>
                } >

              </Tab>

            </Tabs>
          </Col>
          </Row>
       
        <Row>
          
          
          
        </Row>
        <Row>
        <CommentAll slug={'allcomments'}/>

          
        </Row>
          

    </div>

  );
};
}

export default (props) => (
    <AlLChats
        {...props}
        params={useParams()}
    />
);


