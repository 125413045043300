import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import React, { Component } from "react";
import companyLogoBlk from '../img/logo-black.jpeg';

import axios from "axios";
import { Link, useParams } from 'react-router-dom';
import AxiosInstance from "../utils/axiosBase";

import InfiniteScroll from "react-infinite-scroll-component";



const styles = {
    card: {
      backgroundColor: '#B7E0F2',
      borderRadius: 55,
      padding: '3rem'
    },
    cardImage: {
        width: '100%',
        height: '15vw',
        objectfit: 'cover'
    }
  }

class PostSquaresUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            endMessage: "That's All!",
            moreContent: true,
            nextPage: "",
            slug: props.params.slug
        };
    }

    async componentDidMount() {
        
        
        AxiosInstance.get("/post/view/v2/" + this.state.slug + "/")
            .then(response => {
                this.setState({ comments: response.data.results });
                this.setState({nextPage: response.data.next})
                this.state.nextPage != null ? this.setState({moreContent:true}) : this.setState({moreContent:false});
                this.state.nextPage != '' ? this.setState({moreContent:true}) : this.setState({moreContent:false});

                this.setState({endMessage:"That's all!"});
            })
            .catch(error => {
                this.setState({moreContent:false});
                
            });
      };

      fetchMoreData= () => {
        if(this.state.moreContent){
            if(this.state.nextPage != null){
                try{
                    fetch(this.state.nextPage).then(response => {response.json().then(res =>{
                        this.setState({nextPage: res.next});
                        this.setState({ comments: [...this.state.comments, ...res.results] });
                })})
                    .catch((err) => {
                        this.setState({moreContent: false})
                    })
                }catch (err){
                    this.setState({moreContent: false})
                }
            }
            else{
                this.setState({moreContent: false})
            }
        }
      };


    render() {
        return( 
        
            <div id="userpost" style={{height:"500px", overflow:'auto'}} ref={(ref) => this.scrollParentRef = ref}>
                    {this.state.comments?
                    <InfiniteScroll
                        dataLength={this.state.comments.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.moreContent}
                        loader={this.state.moreContent ? (<h4>Loading...</h4>):(<></>)}
                        scrollableTarget="userpost"
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                            <b>You have seen it all ~</b>
                            </p>
                        }
                    >
                        {this.state.comments && this.state.comments.map((item, idx) => {
            
                        return(<Card style={{ width: '17rem' }} key={idx}>
                            {item.post_image_location? (<Card.Img variant="top" class="mx-auto d-none d-md-block" src={window.location.origin+"/"+item.post_image_location} style={styles.cardImage}/>):(<Card.Img variant="top" class="mx-auto d-none d-md-block" src={companyLogoBlk} style={styles.cardImage}/>)}
                            
                            <Card.Body>
                            <Card.Title><Link to={"/post/view/"+item.slug}>{item.title}</Link></Card.Title>
                            <Card.Text>
                                {item.short_description}
                                {item.slug}
                            </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                            <small className="text-muted">{item.author_full_name} {new Date(
                                            item.published_on
                                        ).toDateString()}</small>
                            </Card.Footer>
                        </Card>
                    )})}
                    </InfiniteScroll>:<></>}
        </div>
        )
    };
}


export default (props) => (
    <PostSquaresUser
        {...props}
        params={useParams()}
    />
);