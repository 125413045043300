import React, { Component } from "react";
import { connect, useSelector } from "react-redux";


import Spinner from "../components/UI/spinner";
import CreateAccount from "./CreateAccount";
import Input from "../pages/inputs/inputs";

import { checkValidity } from "../pages/formCheck/checkValidity";

import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FaArrowUp } from "react-icons/fa";

import "./threads.css";

import axios from "axios";
import AxiosInstance from "../utils/axiosBase";



class CreateReply extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commentCreationForm: {
                body: {
                    elementType: "textarea",
                    elementConfig: {
                        type: "textarea",
                        placeholder: "Comment"
                    },
                    value: "",
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                
            },
            isCommentCreationFormValid: false,
            master: this.props.master.slug,
            master_:this.props.master.id,
            slug: this.props.slug,
            from_page: window.location.pathname

            
        };
      };
    inputChangedHandler = (event, inputIndentifier) => {
        const updatedCommentCreationForm = {
            ...this.state.commentCreationForm
        };
        const updatedFormElement = {
            ...this.state.commentCreationForm[inputIndentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValidity(
            updatedFormElement.value,
            updatedFormElement.validation
        );
        updatedFormElement.touched = true;
        updatedCommentCreationForm[inputIndentifier] = updatedFormElement;
        let isFormValid = true;
        for (let inputIndentifier in updatedCommentCreationForm) {
            if(inputIndentifier==='body'){
                
            isFormValid =
                updatedCommentCreationForm[inputIndentifier].valid &&
                isFormValid;
            }
        }
        this.setState({
            commentCreationForm: updatedCommentCreationForm,
            isCommentCreationFormValid: isFormValid
        });
    };

    onFormSubmitHandler = event => {
        event.preventDefault();
        let commentData = {
            body: this.state.commentCreationForm.body.value,
            master: this.state.master,
            id_master: this.state.master_,
            slug: this.state.slug,
            from_page:this.state.from_page
            
        }
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
        };

        
        AxiosInstance.post("/comments/createv2/" + this.state.slug + "/", commentData, config)
            .then(r => {
                this.props.show(false);
                this.props.callback();
                        })
            .catch(error => {
                console.log(error)
                alert("ERROR..!! Something Went Wrong");
            });
    };

    render() {
        let formElements = [];
        var show = true;
        const access = localStorage.getItem("access");

        if(access===null){
            show=false;
        }

        for (let key in this.state.commentCreationForm) {
            if(key==='body'){
            formElements.push({
                id: key,
                config: this.state.commentCreationForm[key]
            });
        }
        }

        let form = (
            <>
            <Container>
                        <div className="MessageSubmit">
                
                {show ? 
                <form onSubmit={this.onFormSubmitHandler}>
                {formElements.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        changed={event =>
                            this.inputChangedHandler(event, formElement.id)
                        }
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                    />
                ))}
                <button disabled={!this.state.isCommentCreationFormValid}>
                    <FaArrowUp/>
                </button>
                </form>
                
                :
                <CreateAccount/>
                    
                }
                </div></Container>
            </>
        );

        return (
            <>
                <div>
                    {this.props.loading ? (
                        <Spinner />
                    ) : (
                        <div>{form}</div>
                    )}
                </div>
            </>
        );
    }
}

export default (props) => (
    <CreateReply
        {...props}
        params={useParams()}
    />
);
