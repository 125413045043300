import React, { Component } from "react";
import { connect, useSelector } from "react-redux";


import Spinner from "../components/UI/spinner";
import CreateAccount from "./CreateAccount";
import Input from "../pages/inputs/inputs";

import { checkValidity } from "../pages/formCheck/checkValidity";

import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FaArrowRight } from "react-icons/fa";


import axios from "axios";
import AxiosInstance from "../utils/axiosBase";

const isExternalURL = (url) => new URL(url).origin !== window.location.origin;


class CreateComment extends Component {
    state = {
        commentCreationForm: {
            body: {
                elementType: "textarea",
                elementConfig: {
                    type: "textarea",
                    placeholder: "Comment"
                },
                value: "",
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
        },
        from_page: window.location.pathname,
        isCommentCreationFormValid: false
    };

    inputChangedHandler = (event, inputIndentifier) => {
        const updatedCommentCreationForm = {
            ...this.state.commentCreationForm
        };
        const updatedFormElement = {
            ...this.state.commentCreationForm[inputIndentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValidity(
            updatedFormElement.value,
            updatedFormElement.validation
        );
        updatedFormElement.touched = true;
        updatedCommentCreationForm[inputIndentifier] = updatedFormElement;
        let isFormValid = true;
        for (let inputIndentifier in updatedCommentCreationForm) {
            isFormValid =
                updatedCommentCreationForm[inputIndentifier].valid &&
                isFormValid;
        }
        this.setState({
            commentCreationForm: updatedCommentCreationForm,
            isCommentCreationFormValid: isFormValid
        });
    };

    onFormSubmitHandler = event => {
        event.preventDefault();
        
        let commentData = {
            body: this.state.commentCreationForm.body.value,
            from_page:this.state.from_page,
            
        }
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
        };
        AxiosInstance.post("/comments/create/" + this.props.slug + "/", commentData, config)
            .then(response => {
                this.state.commentCreationForm.body.value = "";
                this.state.commentCreationForm.body.touched = false;
                this.state.commentCreationForm.body.valid = false;
                this.props.callback()
            })
            .catch(error => {
                //console.log(error);
                //alert("ERROR..!! Something Went Wrong");
            });
    };

    

    render() {
        let formElements = [];

        for (let key in this.state.commentCreationForm) {
            formElements.push({
                id: key,
                config: this.state.commentCreationForm[key]
            });
        }

        let form = (
            <>
            <Container>
                    <Row>
                        <Col>
                <h4
                    style={{
                        fontFamily: "Roboto, sans-serif",
                        fontWeight: "200"
                    }}
                >
                    
                </h4>
                <form onSubmit={this.onFormSubmitHandler}>
                {formElements.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        changed={event =>
                            this.inputChangedHandler(event, formElement.id)
                        }
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                    />
                ))}
                <button disabled={!this.state.isCommentCreationFormValid}>
                    <FaArrowRight/>
                </button>
                </form>  
                </Col>
                </Row></Container>
            </>
        );

        return (
            <>
                <div>
                    {this.props.loading ? (
                        <Spinner />
                    ) : (
                        <div>{form}</div>
                    )}
                </div>
            </>
        );
    }
}

export default (props) => (
    <CreateComment
        {...props}
        params={useParams()}
    />
);
