function findMatch(dict_pass, comment, data){
    var reply_too = comment.id_master;
  
    //data[reply_too].replies = [];
    for(let i=0; i<data.length; i++){
      //alert(data[i].id_master);
      if(data[i].id == reply_too){
        // these are replies to this master comment
        dict_pass.push([data[i].id, comment.id]);
      }
    
    }
    return dict_pass;
  }

  function moveArrayItems(dict_pass, masters) {
    var i,id;
    var masters_ = [];
    var subs_ = [];
    for (i=0; i<dict_pass.length; i++){
        id = dict_pass[i][0];
        if (masters.includes(id)) {
            masters_.push(dict_pass[i])
        }else{
          subs_.push(dict_pass[i])
        }
    }
    dict_pass = [...subs_, ...masters_];
    return dict_pass
}
  
  function cleanData(dict_pass, data, masters){

    
    var cleaned_data = [];
    var completed_ = [];
    try{
    dict_pass = dict_pass.sort((a,b) => a[0] - b[0]).reverse();
    }catch(error){
    }

    for(let i =0; i< data.length; i++){
      data[i]['replies'] = [];
    }
      for(let j = 0; j<dict_pass.length; j++){

          var current_row = data.filter(d => d.id === dict_pass[j][0]);
          if(current_row['replies'] == null){
            //current_row[0]['replies'] = [];
            
            if(completed_.includes(dict_pass[j][1])){
              current_row = cleaned_data.filter(d => d.id === dict_pass[j][1]);
            }
            
            
            // this is the first pass at ordering
            var replies = [];
            replies.push(data.filter(d => d.id === dict_pass[j][1])[0]);
            completed_.push(dict_pass[j][1]);
            current_row[0]['replies'].push(replies[0]);
            cleaned_data.push(current_row)
          }
        
    }
    return cleaned_data
  }
  
  export function order(data){
    var id_order = []
    var masters = [];
    var count_of_masters = 0;
    var count_of_subs = 0; 
    var counter = 0;

    var data = data.results;
    
    // bottom up, which ones are comments to the masters
    for(let i=0; i<data.length; i++){
      if(data[i].id_master!=null){
        count_of_subs++;
        id_order[counter] = data[i];
        counter++;
      }
      else{
        if(!masters.includes(data[i].id)){
          count_of_masters++;
          masters.push(data[i].id);
        }
        
      }
    }

    var dict_pass = []
    var new_ = [];
    for(let i=0; i<id_order.length; i++){
        new_.push(id_order[i]);
    }
    id_order = new_;

    for(let i=0; i<id_order.length; i++){
      try{
      dict_pass = findMatch(dict_pass,id_order[i],  data)
      }catch(error){
      }
    }
    var data_ = cleanData(dict_pass, data, masters)
    var final_data = [];
    for(let i = 0; i< data_.length; i++){
      if(masters.includes(data_[i][0].id)){
        if(!final_data.includes(data_[i][0])){
          final_data.push(data_[i][0]);
        }
      }
    }
    var masters_included = [];
    for(let i = 0; i<final_data.length; i++){
        if(!masters_included.includes(final_data[i].id)){
          masters_included.push(final_data[i].id)
        }
    }

    for(let i = 0; i< masters.length; i++){
        if(!masters_included.includes(masters[i])){
            var no_comments = data.filter(d => d.id === masters[i])[0];
            //no_comments['replies'] = [{}];
            final_data.push(no_comments);
        }
    }

    final_data = final_data.sort((a, b) => a.published_on.localeCompare(b.published_on)).reverse();

    return(final_data)
   };
  