import React, { Component } from "react";
import { connect } from "react-redux";

import Spinner from "../../../components/UI/spinner";
import Input from "../../inputs/inputs";

import axios from "axios";
import AxiosInstance from "../../../utils/axiosBase";

import { checkValidity } from "../../formCheck/checkValidity";
import { useParams } from 'react-router-dom';
import { current } from "@reduxjs/toolkit";

import Row from "react-bootstrap";

import CompanyList from "./ManagementRoles";


class ManEdit extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            editManagementForm: null,
            companyOptions: null,
            isFormValid: true,
            pk: this.props.params.pk,
            currentCompanies:null
        };
    }

    

    async componentDidMount() {
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
        };
        AxiosInstance.get("/admin-panel/users/management/"+this.state.pk+"/", config)
            .then(response => {
                
                this.setState({companyOptions:response})
                this.setState({
                    
                    editManagementForm: {
                        company: {
                            elementType: "dropdown",
                            elementConfig: {
                                type: "dropdown",
                                placeholder: ""
                            },
                            options: this.state.companyOptions,
                            optionsBool:true,
                            value:"",
                            name: "company",
                            valid: true,
                            touched: true
                        },
                        role: {
                            elementType: "input",
                            elementConfig: {
                                type: "text",
                                placeholder: "Role",
                                readOnly: false
                            },
                            optionsBool:false,
                            value: response.data.role,
                            valid: false,
                            touched: false
                        },
                    }
                });
            })
            .catch(error => {
                alert("Something Went Wrong");
            });
        
    }

    

    onFormSubmitEventHandler = event => {
        event.preventDefault();
        let updatedForm = {};
        for (let key in this.state.editManagementForm) {
            updatedForm[key] = this.state.editManagementForm[key].value;
        };
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
        };
        console.log(updatedForm)
        const data = {
                pk: this.state.pk,
                ...updatedForm
            };
        AxiosInstance.put("/admin-panel/users/management/"+this.state.pk+"/save/", data, config)
            .then(response => {
                
                  alert("Completed Save");
                  window.location.reload();  
                })
            .catch(response => {alert(response)});
    };

    inputChangedHandler = (event, inputIndentifier) => {
        console.log(event);
        console.log(inputIndentifier);
        const updatedManForm = {
            ...this.state.editManagementForm
        };
        const updatedFormElement = {
            ...updatedManForm[inputIndentifier]
        };
        if (
            inputIndentifier === "company"
        ) {
            updatedFormElement.value = event.value;
            updatedFormElement.touched = true;
        } else {
            updatedFormElement.value = event.target.value;
            updatedFormElement.valid = checkValidity(
                updatedFormElement.value,
                updatedFormElement.validation
            );
            updatedFormElement.touched = true;
        }
        updatedManForm[inputIndentifier] = updatedFormElement;
        let isFormValid = true;
        for (let inputIndentifier in updatedManForm) {
            isFormValid =
            updatedManForm[inputIndentifier].valid && isFormValid;
        }
        this.setState({
            editManagementForm: updatedManForm,
            isFormValid: isFormValid
        });
    };

    render() {
        let formElements = [];
        for (let key in this.state.editManagementForm) {
            formElements.push({
                id: key,
                config: this.state.editManagementForm[key]
            });
        }
        

        let form = <Spinner />;
        if (this.state.editManagementForm) {
            form = (
                <>
                    <h1
                        style={{
                            fontFamily: "Roboto, sans-serif",
                            fontWeight: "200"
                        }}
                    >
                        Add Management
                    </h1>
                    <form onSubmit={this.onFormSubmitEventHandler}>
                        {formElements.map(formElement => (
                            formElement.config.optionsBool?
                            (<Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                options={this.state.companyOptions}
                                changed={event =>
                                     this.inputChangedHandler(
                                         event,
                                         formElement.id
                                     )
                                }
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                            />):(<Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                changed={event =>
                                    this.inputChangedHandler(
                                        event,
                                        formElement.id
                                    )
                                }
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                            />)
                        ))}
                        <button disabled={!this.state.isFormValid}>
                            Submit
                        </button>
                    </form>
                    <br></br>
            <div>
                <CompanyList pk={this.state.pk}/>
            </div>
        
                </>
            );
        }
        return this.props.loading ? (
            <Spinner />
        ) : (
            <>{form}</>
        );
    }
}



export default (props) => (
    <ManEdit
        {...props}
        params={useParams()}
    />
);