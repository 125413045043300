import React, { Component } from "react";
import { connect } from "react-redux";

import cssClass from "./CommentEdit.css";
import Input from "../../inputs/inputs";

import { checkValidity } from "../../formCheck/checkValidity";

import Spinner from "../../../components/UI/spinner";
import { useParams } from 'react-router-dom';


import axios from "axios";
import AxiosInstance from "../../../utils/axiosBase";



class AdEditComment extends Component {
    state = {
        
        commentEditForm: null,
        isCommentEditFormValid: true
    };

    componentDidMount() {
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
        };
        AxiosInstance.get(
            "/admin-panel/comments/detail/" + this.props.params.pk + "/",
            config
        )
            .then(response => {
                this.setState({
                    commentEditForm: {
                        body: {
                            elementType: "textarea",
                            elementConfig: {
                                type: "textarea",
                                placeholder: "Comment"
                            },
                            value: response.data.body,
                            validation: {
                                required: true,
                                minLength: 5
                            },
                            valid: true,
                            touched: true
                        },
                        is_displayed: {
                            elementType: "checkbox",
                            elementConfig: {
                                type: "checkbox",
                                label: "Published",
                                name: "published",
                                checked: response.data.is_displayed
                                    ? true
                                    : false
                            },
                            value: response.data.is_displayed ? true : false,
                            valid: true,
                            touched: true
                        }
                    }
                });
            })
            .catch(error => {
                alert("Something Went Wrong");
            });
    }

    inputChangedHandler = (event, inputIndentifier) => {
        const updatedCommentEditForm = {
            ...this.state.commentEditForm
        };
        const updatedFormElement = {
            ...updatedCommentEditForm[inputIndentifier]
        };
        if (inputIndentifier === "is_displayed") {
            updatedFormElement.value = !this.state.commentEditForm.is_displayed
                .value;
            updatedFormElement.elementConfig.checked = updatedFormElement.value;
            updatedFormElement.touched = true;
        } else {
            updatedFormElement.value = event.target.value;
            updatedFormElement.valid = checkValidity(
                updatedFormElement.value,
                updatedFormElement.validation
            );
            updatedFormElement.touched = true;
        }
        updatedCommentEditForm[inputIndentifier] = updatedFormElement;
        let isFormValid = true;
        for (let inputIndentifier in updatedCommentEditForm) {
            isFormValid =
                updatedCommentEditForm[inputIndentifier].valid && isFormValid;
        }
        this.setState({
            commentEditForm: updatedCommentEditForm,
            isCommentEditFormValid: isFormValid
        });
    };

    onFormSubmitEventHandler = event => {
        const access = localStorage.getItem("access");
        event.preventDefault();
        let updatedForm = {};
        for (let key in this.state.commentEditForm) {
            updatedForm[key] = this.state.commentEditForm[key].value;
        }
        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
            
        };
        const data= {
            ...updatedForm
        };
        AxiosInstance.patch(
            "/admin-panel/comments/detail/" + this.props.params.pk + "/",
            data, config
        ).then(function(response){
            alert("Post Updated Successfully!");
            window.location = '/admin/view/post-list/'
        }
    )
    .catch(error => {
            alert(error);
        }
    )};

    render() {
        let formElements = [];
        for (let key in this.state.commentEditForm) {
            formElements.push({
                id: key,
                config: this.state.commentEditForm[key]
            });
        }

        let form = <Spinner />;
        if (this.state.commentEditForm) {
            form = (
                <>
                    <h1
                        style={{
                            fontFamily: "Roboto, sans-serif",
                            fontWeight: "200"
                        }}
                    >
                        Edit Comment 
                    </h1>
                    <form onSubmit={this.onFormSubmitEventHandler}>
                        {formElements.map(formElement => (
                            <Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                changed={event =>
                                    this.inputChangedHandler(
                                        event,
                                        formElement.id
                                    )
                                }
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                            />
                        ))}
                        <button disabled={!this.state.isCommentEditFormValid}>
                            Submit
                        </button>
                    </form>
                </>
            );
        }
        return this.props.loading ? (
            <Spinner />
        ) : (
            <div className={cssClass.OuterWrapper}>{form}</div>
        );
    }
}


export default (props) => (
    <AdEditComment
        {...props}
        params={useParams()}
    />
);
