import React, { Component } from "react";

import Spinner from "../../components/UI/spinner";
import cssClass from "./CreatePost.css";
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CommentCreate from "../../components/CommentCreate";
import axios from "axios";
import AxiosInstance from "../../utils/axiosBase";



class PostBodyNA extends Component {
    state = {
        loading: true,
        postBody: null,
        comments: null,
    };

    getPostBody = () => {
        console.log(this.props);
        
        AxiosInstance.get("/post/view/" + this.props.params.slug+"/")
            .then(response =>
                this.setState({ loading: false, postBody: response.data }) 
                
            )
            .catch(err => console.log("Error try again", err));
    };

    getCommentsList = () => {
         AxiosInstance.get("/comments/" + this.props.params.slug + "/")
             .then(response => {
                 this.setState({ comments: response.data });
             })
             .catch(error => {
                 alert("Error Loading Comments. Try Again..!!");
             });
     };

    renderWholePage = () => {
        this.getPostBody();
        this.getCommentsList();
    };
    componentDidMount() {
        this.renderWholePage();
    }

    render() {
        //let postBody = <Spinner />;
        if (this.state.postBody) {
            return(
                <>
                <Container>
                    <Row>
                        <Col>
                        <div className={cssClass.PostBodyDiv}>
                        <h1 className={cssClass.Title}>
                            {this.state.postBody.title}
                        </h1>
                        <p className={cssClass.PublishedDate}>
                            <>Written by: </>{this.state.postBody.author_full_name}
                            <> On: </><>
                            {new Date(
                                this.state.postBody.published_on
                            ).toDateString()}</>
                        </p>
                        <div dangerouslySetInnerHTML={{ __html:this.state.postBody.body}}/>
                        
                        <h1 className={cssClass.CommentHeading}>
                            Comments: {this.state.postBody.total_comments}
                        </h1>
                    </div>
                        </Col>
                    </Row>
                </Container>
                   

                    <CommentCreate
                        slug={this.props.params.slug}
                    />
                    </>
                
            );
        }

    }
}

export default (props) => (
    <PostBodyNA
        {...props}
        params={useParams()}
    />
);

