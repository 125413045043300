import React, { Component, useEffect, setState } from "react";
import Nav from 'react-bootstrap/Nav';

import axios from "axios";
import AxiosInstance from "../utils/axiosBase";

import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavItem from "react-bootstrap/esm/NavItem";

class GetUser extends Component {
    constructor(props) {
      super(props);
      this.state = {
        
        user: false
        };
    }
     
      async componentDidMount() {
            const access = localStorage.getItem("access");
            const config = {
            headers: {
                "Content-Type": "application/json", 
                AUTHORIZATION: "JWT " + access
                
            }
            };
            AxiosInstance.get("/app/user/", config)
            .then((response) => {
                
                const user = response.data;
                this.setState({
                user
                });
            })
            .catch(error => {
                this.state.user=false;
            })
        };
        
            
              
            render() {
                const { user = [] } = this.state;
                
                return(
                    user ? (
                        <>
                        
                            <Nav>
                                <Nav.Item align="center">Hi; <a href={'/profile/'+user.username} ><u>{user.username}</u></a></Nav.Item>
                                <Nav.Link>Search</Nav.Link>
                                
                                
                                <Nav.Link href="/list-post/">Your Posts</Nav.Link>
                                <Nav.Link href="/create-post/">Create Post</Nav.Link>
                                <Nav.Link href={"/profile/"+ user.id +"/edit/"}> Edit Profile</Nav.Link>
                                
                            </Nav>
                        
                            
                        </>
                      ):(<></>)
                );
            }
    };
    export default GetUser;