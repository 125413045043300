import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Spinner from "../../../components/UI/spinner";
import cssClass from "./UserList.css";

import axios from "axios";
import AxiosInstance from "../../../utils/axiosBase";


class UserList extends Component {
    constructor() {
        super();
        this.state = {
            userList: null
        };
    }
    getUsersList = () => {
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "content-type": 'application/json',
                AUTHORIZATION: "JWT " + access
            }
        };
        AxiosInstance.get("/admin-panel/users/", config)
        .then(response => {
            this.setState({userList:response.data});
        })
        .catch(error => {
            alert(error);
        });
    };

    componentDidMount() {
        this.getUsersList();
    }

    componentWillMount() {
        this.getUsersList();
    }

    userDeleteHandler = userPk => {
        const access = localStorage.getItem("access");

        const config = {
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            },
            data: {
                pk: userPk
            }
        };

        let confirmation = window.confirm("Do You Want To Delete This User?");

        if (confirmation) {
            AxiosInstance.delete("/admin-panel/users/detail/", config)
                .then(response => {
                    alert("Post Deleted");
                    this.getUsersList();
                })
                .catch(error => {
                    alert("Something Went Wrong");
                });
        }
    };

    render() {
        let userList = null;
        if (this.state.userList) {
            userList = this.state.userList.map(user => (
                <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.email}</td>
                    {user.is_active ? (
                        <td style={{ color: "green" }}>Active</td>
                    ) : (
                        <td style={{ color: "red" }}>Not Active</td>
                    )}
                    <td>
                        <div className={cssClass.Actions}>
                            <Link to={"/admin/view/user-edit/" + user.id+"/"}>
                                <button>Edit</button>
                            </Link>
                        </div>
                        <div className={cssClass.Actions}>
                            <Link to={"/admin/view/user-management/" + user.id+"/"}>
                                <button>Management</button>
                            </Link>
                        </div>
                        <button
                            red
                            clicked={this.userDeleteHandler}
                            identifier={user.id}
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            ));
        }

        let userListTable = <Spinner />;

        
        userListTable = (
            <div className={cssClass.OuterWrapper}>
                <table className={cssClass.Table}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>{userList}</tbody>
                </table>
            </div>
        );

        return (
            <>
                <div className={cssClass.Title}>Users List</div>
                <div>{this.state.userList ? userListTable : null}</div>
            </>
        );
    }
}

export default UserList;
