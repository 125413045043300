import React from "react";
import  {Component, useState, useRef} from "react";
import { FaLink as LinkIcon} from "react-icons/fa";
import Overlay from 'react-bootstrap/Overlay';


import axios, { AxiosResponse } from 'axios';

import { Link } from "react-router-dom";

import { FaRegNewspaper } from 'react-icons/fa';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';

import InfiniteScroll from "react-infinite-scroll-component";
import AxiosInstance from "../../utils/axiosBase";

function CopyLink({item}){
    const [showAlert, setShowAlert] = useState(false);
    const target = useRef(null);

    return(
    <>
    <Button variant="info" ref={target} onClick={() => {
        navigator.clipboard.writeText(window.location.origin+'/news/'+item.title_slug,
         setShowAlert(!showAlert),
         setTimeout( () => {setShowAlert(showAlert)}, 1000)
    )}}>
    <LinkIcon/>
    </Button>
    <Overlay target={target.current} show={showAlert} placement="top">
    {({
    placement: _placement,
    arrowProps: _arrowProps,
    show: _show,
    popper: _popper,
    hasDoneInitialMeasure: _hasDoneInitialMeasure,
    ...props
    }) => (
    <div
        {...props}
        style={{
        position: 'absolute',
        backgroundColor: 'rgba(65, 239, 99, 0.37)',
        padding: '2px 10px',
        color: 'white',
        borderRadius: 3,
        ...props.style
        }}
    >
        Copied!
    </div>
    )}
    </Overlay>
    </>)
};

function convertUTCDateToLocalDate(date) {
    var d = new Date(date);
    var mins_ = d.getMinutes();
    if (mins_ == 0){
        mins_ = '00'
    }
    if(mins_ < 10 & mins_ > 0){
        mins_ = '0'+String(mins_)
    }
    var datestring = (d.getMonth()+1) +"-"+ d.getDate()  + "-" + d.getFullYear() + " " +d.getHours() + ":" + mins_;

    return datestring;   
};

class NewsContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        moreContent: true,
        nextPage:null,
        response:null,
        tl: []
        };
    }
     
      async componentDidMount() {
        AxiosInstance.get('/app/homepage-news/')
            .then(response => {
                this.setState({response: response});                
                this.setState({tl: response.data.results});
                this.setState({nextPage:response.data.next})
                this.state.nextPage != null ? this.setState({moreContent:true}) : this.setState({moreContent:false});
                this.state.nextPage != '' ? this.setState({moreContent:true}) : this.setState({moreContent:false});
            })
            .catch(error => {
                
                
            });
            
      };

      
      fetchMoreData= () => {
        if(this.state.moreContent){
            if(this.state.nextPage != null){
                try{
                    fetch(this.state.nextPage).then(response => {response.json().then(res =>{
                        this.setState({nextPage: res.next});
                        this.setState({ tl: [...this.state.tl, ...res.results] });
                })})
                    .catch((err) => {
                        this.setState({moreContent: false})
                    })
                }catch (err){
                    this.setState({moreContent: false})
                }
            }
            else{
                this.setState({moreContent: false})
            }
        }
      };
              
      render(){
        return(   
            <>
            <h2>Recent News</h2>
                <div id="tldiv" style={{height:"700px", overflow:'auto'}} ref={(ref) => this.scrollParentRef = ref}>
                <InfiniteScroll
                        dataLength={this.state.tl.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.moreContent}
                        loader={this.state.moreContent ? (<h4>Loading...</h4>):(<></>)}
                        scrollableTarget="tldiv"
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                              <b>You have seen it all ~</b>
                            </p>
                          }
                    >
                {this.state.tl.map((item, index) => {
                    return(
                    <Card style={{ width: 'auto' }} key={index}>
                        <Card.Body>
                            
                            <Card.Text>
                                <CopyLink item={item}/>
                                <Link to={`/company/${item.ticker_no_exchange}/${item.title_slug}`}><Button variant="primary"><FaRegNewspaper /></Button></Link>
                                <a href={"/company/"+item.ticker_no_exchange}>{item.ticker}</a> &bull; <b>{convertUTCDateToLocalDate(item.pubdate)}</b> &bull;
                                <small className="text-muted">
                                    {item.title}
                                </small>
                            </Card.Text>
                        </Card.Body>
                    </Card>)
                    
                    })
                }
                </InfiniteScroll></div>
                </>
        )
  
    };
}

    export default (props) => (
        <NewsContainer
            {...props}
            params={useParams()}
        />
    );