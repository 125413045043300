import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Spinner from "../../../components/UI/spinner";
import cssClass from "../PostList/PostList.js";
import Button from 'react-bootstrap/Button';


import axios from "axios";
import AxiosInstance from "../../../utils/axiosBase";
import { useParams } from 'react-router-dom';


class CompanyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pk: props.pk,
            currentCompanies: null
        };
    }

    getAllPosts = () => {
        const access = localStorage.getItem("access");
        const config = {
            headers: {
                "content-type": 'application/json',
                AUTHORIZATION: "JWT " + access
            }
        };
        AxiosInstance.get("/admin-panel/users/management/"+this.state.pk+"/save/", config, config)
        .then(response => {
            this.setState({currentCompanies:response.data});
        })
        .catch(error => {
            alert(error);
        });
    };
    async componentDidMount() {
        this.getAllPosts();
    }

    postDeleteHandler = id => {
        const access = localStorage.getItem("access");

        const config = {
            data: {id:id},
            headers: {
                "Content-Type": "application/json",
                AUTHORIZATION: "JWT " + access
            }
        };

        let confirmation = window.confirm("Switch state?");

        if (confirmation) {
            AxiosInstance.delete(
                "/admin-panel/users/management/"+this.state.pk+"/save/", config
            )
                .then(response => {
                    alert("Updated");
                    window.location.reload()
                })
                .catch(error => {
                    alert("Something Went Wrong");
                });
        }
    };

    render() {
        let postsList = null;
        console.log(this.state.currentCompanies)
        if (this.state.currentCompanies) {
            postsList = this.state.currentCompanies.map((post, index) => (
                <tr key={post.id}>
                    <td>{post.role}</td>
                    <td>{post.company_name}</td>
                    <td>{''+post.management}</td>
                    
                    <td>
                        
                    <Button variant="outline-danger" onClick={() => this.postDeleteHandler(post.id)}>Switch State</Button>
                    </td>
                </tr>
            ));
        }

        let postsListTable = <Spinner />;

        postsListTable = (
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Role</th>
                                <th>Company</th>
                                <th>Active</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>{postsList}</tbody>
                    </table>
                </div>
            );
        
        return (
            <>
                <div className={cssClass.Title}>All Roles</div>
                <div>{this.state.currentCompanies ? postsListTable : null}</div>
            </>
        );
    }
}


export default (props) => (
    <CompanyList
        {...props}
        params={useParams()}
    />
);
