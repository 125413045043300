import React from "react";

import companyLogoBlk from '../img/logo-black.jpeg';
import companyLogoWht from '../img/logo-white.jpg';
import { Link } from "react-router-dom";

import "./Footer.css";

const Footer = () => {
  
  return (
        <footer className="footer">
                    
            <div className="logo" align="center">
                <Link to="/"><div className="logo">
                <img src={companyLogoWht}/>
              </div>
                
                </Link>
            </div>
            <p className="text-footer">
                SETUPS - CONTENT - COMMUNITY
                <br></br>
                Copyright © - All Rights Reserved          
                
            </p>
            
        </footer>
    );
};

export default Footer;