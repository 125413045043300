import { configureStore } from "@reduxjs/toolkit";
import api from "./middlewares/api";
import reducer from "./reducer";

export default function () {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api),
  });
}
// import { configureStore } from '@reduxjs/toolkit'

// import logger from 'redux-logger'

// import rootReducer from './reducer'

// export default function () {
//   return configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
// });
// }